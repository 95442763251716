import { Suspense } from 'react';
import { type Route } from 'type-route';

import { routes } from '../../router';
import { Breadcrumbs } from '../core/breadcrumbs';
import { ErrorBoundary } from '../core/error_boundary';
import { Spinner } from '../core/spinner';
import { ChartRepoHeading } from './chart_repo_heading';
import { ChartTable } from './chart_table';

interface ChartRepoDetailProps {
  route: Route<typeof routes.chartRepositoryDetail>;
}

export function ChartRepoDetail(props: ChartRepoDetailProps) {
  const { route } = props;
  const breadcrumbs = [
    {
      name: 'Chart repositories',
      link: routes.chartRepositoryList({
        projectId: route.params.projectId,
      }).link,
      current: true,
    },
  ];

  return (
    <div>
      <Breadcrumbs pages={breadcrumbs} />
      <ErrorBoundary>
        <Suspense fallback={<Spinner />}>
          <ChartRepoHeading
            projectId={route.params.projectId}
            repoId={route.params.chartRepositoryId} />
        </Suspense>
      </ErrorBoundary>
      <ErrorBoundary>
        <Suspense fallback={<Spinner />}>
          <ChartTable
            projectId={route.params.projectId}
            repoId={route.params.chartRepositoryId} />
        </Suspense>
      </ErrorBoundary>
    </div>
  );
}
