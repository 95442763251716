// @generated by protoc-gen-es v1.8.0 with parameter "target=ts"
// @generated from file annealing/sts/v1/service.proto (package annealing.sts.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message annealing.sts.v1.CreateSessionRequest
 */
export class CreateSessionRequest extends Message<CreateSessionRequest> {
  /**
   * The federated identity token to use.
   *
   * @generated from field: string identity_token = 2;
   */
  identityToken = "";

  /**
   * The identity provider to use.
   *
   * @generated from field: string identity_provider = 3;
   */
  identityProvider = "";

  /**
   * The session ID to use.
   *
   * @generated from field: string session_id = 4;
   */
  sessionId = "";

  /**
   * The service account to use.
   *
   * @generated from field: string service_account = 5;
   */
  serviceAccount = "";

  constructor(data?: PartialMessage<CreateSessionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.sts.v1.CreateSessionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "identity_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "identity_provider", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "session_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "service_account", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateSessionRequest {
    return new CreateSessionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateSessionRequest {
    return new CreateSessionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateSessionRequest {
    return new CreateSessionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateSessionRequest | PlainMessage<CreateSessionRequest> | undefined, b: CreateSessionRequest | PlainMessage<CreateSessionRequest> | undefined): boolean {
    return proto3.util.equals(CreateSessionRequest, a, b);
  }
}

/**
 * @generated from message annealing.sts.v1.GetUserInfoRequest
 */
export class GetUserInfoRequest extends Message<GetUserInfoRequest> {
  constructor(data?: PartialMessage<GetUserInfoRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.sts.v1.GetUserInfoRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetUserInfoRequest {
    return new GetUserInfoRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetUserInfoRequest {
    return new GetUserInfoRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetUserInfoRequest {
    return new GetUserInfoRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetUserInfoRequest | PlainMessage<GetUserInfoRequest> | undefined, b: GetUserInfoRequest | PlainMessage<GetUserInfoRequest> | undefined): boolean {
    return proto3.util.equals(GetUserInfoRequest, a, b);
  }
}

