import { useForm } from 'react-hook-form';

import { createProject } from '../../hooks/use_organization_api';
import { routes } from '../../router';
import { createToast } from '../core/toast';

interface ProjectCreateFormData {
  name: string;
}

export function ProjectCreate() {
  const { register, handleSubmit } = useForm<ProjectCreateFormData>();

  const onSubmit = async (data: ProjectCreateFormData) => {
    try {
      const project = await createProject({
        project: {
          name: '',
          displayName: data.name,
          clusterCount: 0,
          assetCount: 0,
          appCount: 0,
          rolloutCount: 0,
          chartRepositoryCount: 0,
          gitRepositoryCount: 0,
          sshKeyCount: 0,
          sshKnownHostCount: 0,
          roleBindingCount: 0,
          identityProviderCount: 0,
          serviceAccountCount: 0,
          ignoreDifferenceCount: 0,
        },
      });
      createToast(`Project "${data.name}" created.`);
      routes.projectDetail({
        projectId: project.name.split('/')[1],
      }).push();
    } catch (err) {
      createToast(`Error creating project: ${err}`);
    }
  };

  return (
    <div className="p-5">
      <nav>
        <a
          {...routes.home().link}
          className="flex items-center text-sm font-medium leading-5 text-gray-500 transition duration-150 ease-in-out hover:text-gray-700"
        >
          <svg
            className="mr-1 -ml-1 h-5 w-5 flex-shrink-0 text-gray-400"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
              clipRule="evenodd"
            />
          </svg>
          Home
        </a>
      </nav>
      <main className="mx-auto max-w-xl px-4 pt-10 pb-12 lg:pb-16">
        <div className="mt-2 md:flex md:items-center md:justify-between">
          <div className="min-w-0 flex-1">
            <h1 className="text-2xl font-medium text-gray-900">New project</h1>
          </div>
        </div>
        <p className="mt-2 max-w-xl text-sm text-gray-500">
          A project is a container and ownership boundary for a set of
          resources, including clusters, applications, and Git repositories.
          {' '}
          <a
            href="#"
            className="font-medium text-indigo-600 hover:text-indigo-500"
          >
            Learn more
          </a>
          .
        </p>
        <form
          className="max-w-xl space-y-8 divide-y divide-gray-200"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="mt-6">
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700"
            >
              Display name
            </label>
            <div className="mt-1">
              <input
                {...register('name')}
                type="text"
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>
          </div>
          <div className="pt-5">
            <div className="flex justify-end">
              <button
                type="submit"
                className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Create
              </button>
            </div>
          </div>
        </form>
      </main>
    </div>
  );
}
