import { groups, useRoute } from '../router';

import { Login } from './auth/login';
import { SignUp } from './auth/signup';
import { ClusterCreate as KubeClusterCreate } from './compute/cluster_create';
import { ClusterDetail as KubeClusterDetail } from './compute/cluster_detail';
import { ClusterList as KubeClusterList } from './compute/cluster_list';
import { MachineCreate } from './compute/machine_create';
import { MachineDetail } from './compute/machine_detail';
import { MachineList } from './compute/machine_list';
import { OperationDetail } from './compute/operation_detail';
import { OperationList } from './compute/operation_list';
import { Dashboard } from './dashboard/dashboard';
import { ApplicationCreate } from './delivery/application_create';
import { ApplicationDetail } from './delivery/application_detail';
import { ApplicationList } from './delivery/application_list';
import { AssetDetail } from './delivery/asset_detail';
import { ChartRepoCreate } from './delivery/chart_repo_create';
import { ChartRepoDetail } from './delivery/chart_repo_detail';
import { ChartRepoList } from './delivery/chart_repo_list';
import { ClusterCreate } from './delivery/cluster_create';
import { ClusterDetail } from './delivery/cluster_detail';
import { ClusterList } from './delivery/cluster_list';
import { GitRepoCreate } from './delivery/git_repo_create';
import { GitRepoDetail } from './delivery/git_repo_detail';
import { GitRepoList } from './delivery/git_repo_list';
import { IgnoreDifferenceCreate } from './delivery/ignore_difference_create';
import { IgnoreDifferenceDetail } from './delivery/ignore_difference_detail';
import { IgnoreDifferenceList } from './delivery/ignore_difference_list';
import { IncarnationDetail } from './delivery/incarnation_detail';
import { RolloutDetail } from './delivery/rollout_detail';
import { SSHKeyCreate } from './delivery/ssh_key_create';
import { SSHKeyDetail } from './delivery/ssh_key_detail';
import { SSHKeyList } from './delivery/ssh_key_list';
import { SSHKnownHostCreate } from './delivery/ssh_known_host_create';
import { SSHKnownHostDetail } from './delivery/ssh_known_host_detail';
import { SSHKnownHostList } from './delivery/ssh_known_host_list';
import { Home } from './home';
import { OIDCProviderCreate } from './iam/oidc_provider_create';
import { OIDCProviderDetail } from './iam/oidc_provider_detail';
import { OIDCProviderList } from './iam/oidc_provider_list';
import { RoleBindingCreate } from './iam/role_binding_create';
import { RoleBindingDetail } from './iam/role_binding_detail';
import { RoleBindingList } from './iam/role_binding_list';
import { ServiceAccountCreate } from './iam/service_account_create';
import { ServiceAccountDetail } from './iam/service_account_detail';
import { ServiceAccountList } from './iam/service_account_list';
import { ProjectCreate } from './layout/project_create';
import { Settings } from './layout/settings';
import { SidebarLayout } from './layout/sidebar_layout';
import { User } from './layout/user';

export function Main() {
  const route = useRoute();
  return (
    <>
      {route.name === 'home' && <Home />}
      {route.name === 'user' && <User />}
      {route.name === 'projectCreate' && <ProjectCreate />}
      {route.name === 'signUp' && <SignUp />}
      {route.name === 'login' && <Login />}
      {route.name === 'projectDetail' && (
        <SidebarLayout route={route}>
          <Dashboard route={route} />
        </SidebarLayout>
      )}
      {route.name === 'projectSettings' && (
        <SidebarLayout route={route}>
          <Settings route={route} />
        </SidebarLayout>
      )}
      {route.name === 'applicationList' && (
        <SidebarLayout route={route}>
          <ApplicationList route={route} />
        </SidebarLayout>
      )}
      {route.name === 'applicationCreate' && (
        <SidebarLayout route={route}>
          <ApplicationCreate route={route} />
        </SidebarLayout>
      )}
      {groups.applicationDetail.has(route) && (
        <SidebarLayout route={route}>
          <ApplicationDetail route={route} />
        </SidebarLayout>
      )}
      {route.name === 'assetDetail' && (
        <SidebarLayout route={route}>
          <AssetDetail route={route} />
        </SidebarLayout>
      )}
      {route.name === 'incarnationDetail' && (
        <SidebarLayout route={route}>
          <IncarnationDetail route={route} />
        </SidebarLayout>
      )}
      {route.name === 'rolloutDetail' && (
        <SidebarLayout route={route}>
          <RolloutDetail route={route} />
        </SidebarLayout>
      )}
      {route.name === 'clusterList' && (
        <SidebarLayout route={route}>
          <ClusterList route={route} />
        </SidebarLayout>
      )}
      {route.name === 'clusterCreate' && (
        <SidebarLayout route={route}>
          <ClusterCreate route={route} />
        </SidebarLayout>
      )}
      {groups.clusterDetail.has(route) && (
        <SidebarLayout route={route}>
          <ClusterDetail route={route} />
        </SidebarLayout>
      )}
      {route.name === 'gitRepositoryList' && (
        <SidebarLayout route={route}>
          <GitRepoList route={route} />
        </SidebarLayout>
      )}
      {route.name === 'gitRepositoryCreate' && (
        <SidebarLayout route={route}>
          <GitRepoCreate route={route} />
        </SidebarLayout>
      )}
      {route.name === 'gitRepositoryDetail' && (
        <SidebarLayout route={route}>
          <GitRepoDetail route={route} />
        </SidebarLayout>
      )}
      {route.name === 'chartRepositoryList' && (
        <SidebarLayout route={route}>
          <ChartRepoList route={route} />
        </SidebarLayout>
      )}
      {route.name === 'chartRepositoryCreate' && (
        <SidebarLayout route={route}>
          <ChartRepoCreate route={route} />
        </SidebarLayout>
      )}
      {route.name === 'chartRepositoryDetail' && (
        <SidebarLayout route={route}>
          <ChartRepoDetail route={route} />
        </SidebarLayout>
      )}
      {route.name === 'sshKeyList' && (
        <SidebarLayout route={route}>
          <SSHKeyList route={route} />
        </SidebarLayout>
      )}
      {route.name === 'sshKeyCreate' && (
        <SidebarLayout route={route}>
          <SSHKeyCreate route={route} />
        </SidebarLayout>
      )}
      {route.name === 'sshKeyDetail' && (
        <SidebarLayout route={route}>
          <SSHKeyDetail route={route} />
        </SidebarLayout>
      )}
      {route.name === 'sshKnownHostList' && (
        <SidebarLayout route={route}>
          <SSHKnownHostList route={route} />
        </SidebarLayout>
      )}
      {route.name === 'sshKnownHostCreate' && (
        <SidebarLayout route={route}>
          <SSHKnownHostCreate route={route} />
        </SidebarLayout>
      )}
      {route.name === 'sshKnownHostDetail' && (
        <SidebarLayout route={route}>
          <SSHKnownHostDetail route={route} />
        </SidebarLayout>
      )}
      {route.name === 'ignoreDifferenceList' && (
        <SidebarLayout route={route}>
          <IgnoreDifferenceList route={route} />
        </SidebarLayout>
      )}
      {route.name === 'ignoreDifferenceCreate' && (
        <SidebarLayout route={route}>
          <IgnoreDifferenceCreate route={route} />
        </SidebarLayout>
      )}
      {route.name === 'ignoreDifferenceDetail' && (
        <SidebarLayout route={route}>
          <IgnoreDifferenceDetail route={route} />
        </SidebarLayout>
      )}
      {route.name === 'roleBindingList' && (
        <SidebarLayout route={route}>
          <RoleBindingList route={route} />
        </SidebarLayout>
      )}
      {route.name === 'roleBindingCreate' && (
        <SidebarLayout route={route}>
          <RoleBindingCreate route={route} />
        </SidebarLayout>
      )}
      {route.name === 'roleBindingDetail' && (
        <SidebarLayout route={route}>
          <RoleBindingDetail route={route} />
        </SidebarLayout>
      )}
      {route.name === 'oidcProviderList' && (
        <SidebarLayout route={route}>
          <OIDCProviderList route={route} />
        </SidebarLayout>
      )}
      {route.name === 'oidcProviderCreate' && (
        <SidebarLayout route={route}>
          <OIDCProviderCreate route={route} />
        </SidebarLayout>
      )}
      {route.name === 'oidcProviderDetail' && (
        <SidebarLayout route={route}>
          <OIDCProviderDetail route={route} />
        </SidebarLayout>
      )}
      {route.name === 'serviceAccountList' && (
        <SidebarLayout route={route}>
          <ServiceAccountList route={route} />
        </SidebarLayout>
      )}
      {route.name === 'serviceAccountCreate' && (
        <SidebarLayout route={route}>
          <ServiceAccountCreate route={route} />
        </SidebarLayout>
      )}
      {route.name === 'serviceAccountDetail' && (
        <SidebarLayout route={route}>
          <ServiceAccountDetail route={route} />
        </SidebarLayout>
      )}
      {route.name === 'computeClusterList' && (
        <SidebarLayout route={route}>
          <KubeClusterList route={route} />
        </SidebarLayout>
      )}
      {route.name === 'computeClusterCreate' && (
        <SidebarLayout route={route}>
          <KubeClusterCreate route={route} />
        </SidebarLayout>
      )}
      {route.name === 'computeClusterDetail' && (
        <SidebarLayout route={route}>
          <KubeClusterDetail route={route} />
        </SidebarLayout>
      )}
      {route.name === 'computeMachineList' && (
        <SidebarLayout route={route}>
          <MachineList route={route} />
        </SidebarLayout>
      )}
      {route.name === 'computeMachineCreate' && (
        <SidebarLayout route={route}>
          <MachineCreate route={route} />
        </SidebarLayout>
      )}
      {route.name === 'computeMachineDetail' && (
        <SidebarLayout route={route}>
          <MachineDetail route={route} />
        </SidebarLayout>
      )}
      {route.name === 'computeOperationList' && (
        <SidebarLayout route={route}>
          <OperationList route={route} />
        </SidebarLayout>
      )}
      {route.name === 'computeOperationDetail' && (
        <SidebarLayout route={route}>
          <OperationDetail route={route} />
        </SidebarLayout>
      )}
      {route.name === false && <div>Not Found</div>}
    </>
  );
}
