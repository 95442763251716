// @generated by protoc-gen-es v1.8.0 with parameter "target=ts"
// @generated from file annealing/delivery/v1/service.proto (package annealing.delivery.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { FieldMask, Message, proto3 } from "@bufbuild/protobuf";
import { Application, Asset, Chart, ChartRepository, Cluster, GitRepository, IgnoreDifference, Incarnation, IncarnationAsset, IncarnationFile, Rollout, RolloutAsset, RolloutStep, SshKey, SshKnownHost } from "./resources_pb.js";

/**
 * @generated from message annealing.delivery.v1.CreateSshKeyRequest
 */
export class CreateSshKeyRequest extends Message<CreateSshKeyRequest> {
  /**
   * @generated from field: string parent = 1;
   */
  parent = "";

  /**
   * @generated from field: annealing.delivery.v1.SshKey ssh_key = 2;
   */
  sshKey?: SshKey;

  /**
   * @generated from field: string ssh_key_id = 3;
   */
  sshKeyId = "";

  constructor(data?: PartialMessage<CreateSshKeyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.CreateSshKeyRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "parent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "ssh_key", kind: "message", T: SshKey },
    { no: 3, name: "ssh_key_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateSshKeyRequest {
    return new CreateSshKeyRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateSshKeyRequest {
    return new CreateSshKeyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateSshKeyRequest {
    return new CreateSshKeyRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateSshKeyRequest | PlainMessage<CreateSshKeyRequest> | undefined, b: CreateSshKeyRequest | PlainMessage<CreateSshKeyRequest> | undefined): boolean {
    return proto3.util.equals(CreateSshKeyRequest, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.GetSshKeyRequest
 */
export class GetSshKeyRequest extends Message<GetSshKeyRequest> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  constructor(data?: PartialMessage<GetSshKeyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.GetSshKeyRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSshKeyRequest {
    return new GetSshKeyRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSshKeyRequest {
    return new GetSshKeyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSshKeyRequest {
    return new GetSshKeyRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetSshKeyRequest | PlainMessage<GetSshKeyRequest> | undefined, b: GetSshKeyRequest | PlainMessage<GetSshKeyRequest> | undefined): boolean {
    return proto3.util.equals(GetSshKeyRequest, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.ListSshKeysRequest
 */
export class ListSshKeysRequest extends Message<ListSshKeysRequest> {
  /**
   * @generated from field: string parent = 1;
   */
  parent = "";

  /**
   * @generated from field: int32 page_size = 2;
   */
  pageSize = 0;

  /**
   * @generated from field: string page_token = 3;
   */
  pageToken = "";

  constructor(data?: PartialMessage<ListSshKeysRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.ListSshKeysRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "parent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListSshKeysRequest {
    return new ListSshKeysRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListSshKeysRequest {
    return new ListSshKeysRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListSshKeysRequest {
    return new ListSshKeysRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListSshKeysRequest | PlainMessage<ListSshKeysRequest> | undefined, b: ListSshKeysRequest | PlainMessage<ListSshKeysRequest> | undefined): boolean {
    return proto3.util.equals(ListSshKeysRequest, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.ListSshKeysResponse
 */
export class ListSshKeysResponse extends Message<ListSshKeysResponse> {
  /**
   * @generated from field: repeated annealing.delivery.v1.SshKey ssh_keys = 1;
   */
  sshKeys: SshKey[] = [];

  /**
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = "";

  /**
   * @generated from field: string previous_page_token = 3;
   */
  previousPageToken = "";

  /**
   * @generated from field: int32 total_size = 4;
   */
  totalSize = 0;

  /**
   * @generated from field: int32 start_index = 5;
   */
  startIndex = 0;

  constructor(data?: PartialMessage<ListSshKeysResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.ListSshKeysResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ssh_keys", kind: "message", T: SshKey, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "previous_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "total_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "start_index", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListSshKeysResponse {
    return new ListSshKeysResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListSshKeysResponse {
    return new ListSshKeysResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListSshKeysResponse {
    return new ListSshKeysResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListSshKeysResponse | PlainMessage<ListSshKeysResponse> | undefined, b: ListSshKeysResponse | PlainMessage<ListSshKeysResponse> | undefined): boolean {
    return proto3.util.equals(ListSshKeysResponse, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.DeleteSshKeyRequest
 */
export class DeleteSshKeyRequest extends Message<DeleteSshKeyRequest> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  constructor(data?: PartialMessage<DeleteSshKeyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.DeleteSshKeyRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteSshKeyRequest {
    return new DeleteSshKeyRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteSshKeyRequest {
    return new DeleteSshKeyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteSshKeyRequest {
    return new DeleteSshKeyRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteSshKeyRequest | PlainMessage<DeleteSshKeyRequest> | undefined, b: DeleteSshKeyRequest | PlainMessage<DeleteSshKeyRequest> | undefined): boolean {
    return proto3.util.equals(DeleteSshKeyRequest, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.CreateSshKnownHostRequest
 */
export class CreateSshKnownHostRequest extends Message<CreateSshKnownHostRequest> {
  /**
   * @generated from field: string parent = 1;
   */
  parent = "";

  /**
   * @generated from field: annealing.delivery.v1.SshKnownHost ssh_known_host = 2;
   */
  sshKnownHost?: SshKnownHost;

  /**
   * @generated from field: string ssh_known_host_id = 3;
   */
  sshKnownHostId = "";

  constructor(data?: PartialMessage<CreateSshKnownHostRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.CreateSshKnownHostRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "parent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "ssh_known_host", kind: "message", T: SshKnownHost },
    { no: 3, name: "ssh_known_host_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateSshKnownHostRequest {
    return new CreateSshKnownHostRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateSshKnownHostRequest {
    return new CreateSshKnownHostRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateSshKnownHostRequest {
    return new CreateSshKnownHostRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateSshKnownHostRequest | PlainMessage<CreateSshKnownHostRequest> | undefined, b: CreateSshKnownHostRequest | PlainMessage<CreateSshKnownHostRequest> | undefined): boolean {
    return proto3.util.equals(CreateSshKnownHostRequest, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.GetSshKnownHostRequest
 */
export class GetSshKnownHostRequest extends Message<GetSshKnownHostRequest> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  constructor(data?: PartialMessage<GetSshKnownHostRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.GetSshKnownHostRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSshKnownHostRequest {
    return new GetSshKnownHostRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSshKnownHostRequest {
    return new GetSshKnownHostRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSshKnownHostRequest {
    return new GetSshKnownHostRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetSshKnownHostRequest | PlainMessage<GetSshKnownHostRequest> | undefined, b: GetSshKnownHostRequest | PlainMessage<GetSshKnownHostRequest> | undefined): boolean {
    return proto3.util.equals(GetSshKnownHostRequest, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.ListSshKnownHostsRequest
 */
export class ListSshKnownHostsRequest extends Message<ListSshKnownHostsRequest> {
  /**
   * @generated from field: string parent = 1;
   */
  parent = "";

  /**
   * @generated from field: int32 page_size = 2;
   */
  pageSize = 0;

  /**
   * @generated from field: string page_token = 3;
   */
  pageToken = "";

  constructor(data?: PartialMessage<ListSshKnownHostsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.ListSshKnownHostsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "parent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListSshKnownHostsRequest {
    return new ListSshKnownHostsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListSshKnownHostsRequest {
    return new ListSshKnownHostsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListSshKnownHostsRequest {
    return new ListSshKnownHostsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListSshKnownHostsRequest | PlainMessage<ListSshKnownHostsRequest> | undefined, b: ListSshKnownHostsRequest | PlainMessage<ListSshKnownHostsRequest> | undefined): boolean {
    return proto3.util.equals(ListSshKnownHostsRequest, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.ListSshKnownHostsResponse
 */
export class ListSshKnownHostsResponse extends Message<ListSshKnownHostsResponse> {
  /**
   * @generated from field: repeated annealing.delivery.v1.SshKnownHost ssh_known_hosts = 1;
   */
  sshKnownHosts: SshKnownHost[] = [];

  /**
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = "";

  /**
   * @generated from field: string previous_page_token = 3;
   */
  previousPageToken = "";

  /**
   * @generated from field: int32 total_size = 4;
   */
  totalSize = 0;

  /**
   * @generated from field: int32 start_index = 5;
   */
  startIndex = 0;

  constructor(data?: PartialMessage<ListSshKnownHostsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.ListSshKnownHostsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ssh_known_hosts", kind: "message", T: SshKnownHost, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "previous_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "total_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "start_index", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListSshKnownHostsResponse {
    return new ListSshKnownHostsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListSshKnownHostsResponse {
    return new ListSshKnownHostsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListSshKnownHostsResponse {
    return new ListSshKnownHostsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListSshKnownHostsResponse | PlainMessage<ListSshKnownHostsResponse> | undefined, b: ListSshKnownHostsResponse | PlainMessage<ListSshKnownHostsResponse> | undefined): boolean {
    return proto3.util.equals(ListSshKnownHostsResponse, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.DeleteSshKnownHostRequest
 */
export class DeleteSshKnownHostRequest extends Message<DeleteSshKnownHostRequest> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  constructor(data?: PartialMessage<DeleteSshKnownHostRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.DeleteSshKnownHostRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteSshKnownHostRequest {
    return new DeleteSshKnownHostRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteSshKnownHostRequest {
    return new DeleteSshKnownHostRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteSshKnownHostRequest {
    return new DeleteSshKnownHostRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteSshKnownHostRequest | PlainMessage<DeleteSshKnownHostRequest> | undefined, b: DeleteSshKnownHostRequest | PlainMessage<DeleteSshKnownHostRequest> | undefined): boolean {
    return proto3.util.equals(DeleteSshKnownHostRequest, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.CreateIgnoreDifferenceRequest
 */
export class CreateIgnoreDifferenceRequest extends Message<CreateIgnoreDifferenceRequest> {
  /**
   * @generated from field: string parent = 1;
   */
  parent = "";

  /**
   * @generated from field: annealing.delivery.v1.IgnoreDifference ignore_difference = 2;
   */
  ignoreDifference?: IgnoreDifference;

  /**
   * @generated from field: string ignore_difference_id = 3;
   */
  ignoreDifferenceId = "";

  constructor(data?: PartialMessage<CreateIgnoreDifferenceRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.CreateIgnoreDifferenceRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "parent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "ignore_difference", kind: "message", T: IgnoreDifference },
    { no: 3, name: "ignore_difference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateIgnoreDifferenceRequest {
    return new CreateIgnoreDifferenceRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateIgnoreDifferenceRequest {
    return new CreateIgnoreDifferenceRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateIgnoreDifferenceRequest {
    return new CreateIgnoreDifferenceRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateIgnoreDifferenceRequest | PlainMessage<CreateIgnoreDifferenceRequest> | undefined, b: CreateIgnoreDifferenceRequest | PlainMessage<CreateIgnoreDifferenceRequest> | undefined): boolean {
    return proto3.util.equals(CreateIgnoreDifferenceRequest, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.GetIgnoreDifferenceRequest
 */
export class GetIgnoreDifferenceRequest extends Message<GetIgnoreDifferenceRequest> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  constructor(data?: PartialMessage<GetIgnoreDifferenceRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.GetIgnoreDifferenceRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetIgnoreDifferenceRequest {
    return new GetIgnoreDifferenceRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetIgnoreDifferenceRequest {
    return new GetIgnoreDifferenceRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetIgnoreDifferenceRequest {
    return new GetIgnoreDifferenceRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetIgnoreDifferenceRequest | PlainMessage<GetIgnoreDifferenceRequest> | undefined, b: GetIgnoreDifferenceRequest | PlainMessage<GetIgnoreDifferenceRequest> | undefined): boolean {
    return proto3.util.equals(GetIgnoreDifferenceRequest, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.ListIgnoreDifferencesRequest
 */
export class ListIgnoreDifferencesRequest extends Message<ListIgnoreDifferencesRequest> {
  /**
   * @generated from field: string parent = 1;
   */
  parent = "";

  /**
   * @generated from field: int32 page_size = 2;
   */
  pageSize = 0;

  /**
   * @generated from field: string page_token = 3;
   */
  pageToken = "";

  constructor(data?: PartialMessage<ListIgnoreDifferencesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.ListIgnoreDifferencesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "parent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListIgnoreDifferencesRequest {
    return new ListIgnoreDifferencesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListIgnoreDifferencesRequest {
    return new ListIgnoreDifferencesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListIgnoreDifferencesRequest {
    return new ListIgnoreDifferencesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListIgnoreDifferencesRequest | PlainMessage<ListIgnoreDifferencesRequest> | undefined, b: ListIgnoreDifferencesRequest | PlainMessage<ListIgnoreDifferencesRequest> | undefined): boolean {
    return proto3.util.equals(ListIgnoreDifferencesRequest, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.ListIgnoreDifferencesResponse
 */
export class ListIgnoreDifferencesResponse extends Message<ListIgnoreDifferencesResponse> {
  /**
   * @generated from field: repeated annealing.delivery.v1.IgnoreDifference ignore_differences = 1;
   */
  ignoreDifferences: IgnoreDifference[] = [];

  /**
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = "";

  /**
   * @generated from field: string previous_page_token = 3;
   */
  previousPageToken = "";

  /**
   * @generated from field: int32 total_size = 4;
   */
  totalSize = 0;

  /**
   * @generated from field: int32 start_index = 5;
   */
  startIndex = 0;

  constructor(data?: PartialMessage<ListIgnoreDifferencesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.ListIgnoreDifferencesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ignore_differences", kind: "message", T: IgnoreDifference, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "previous_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "total_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "start_index", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListIgnoreDifferencesResponse {
    return new ListIgnoreDifferencesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListIgnoreDifferencesResponse {
    return new ListIgnoreDifferencesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListIgnoreDifferencesResponse {
    return new ListIgnoreDifferencesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListIgnoreDifferencesResponse | PlainMessage<ListIgnoreDifferencesResponse> | undefined, b: ListIgnoreDifferencesResponse | PlainMessage<ListIgnoreDifferencesResponse> | undefined): boolean {
    return proto3.util.equals(ListIgnoreDifferencesResponse, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.DeleteIgnoreDifferenceRequest
 */
export class DeleteIgnoreDifferenceRequest extends Message<DeleteIgnoreDifferenceRequest> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  constructor(data?: PartialMessage<DeleteIgnoreDifferenceRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.DeleteIgnoreDifferenceRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteIgnoreDifferenceRequest {
    return new DeleteIgnoreDifferenceRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteIgnoreDifferenceRequest {
    return new DeleteIgnoreDifferenceRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteIgnoreDifferenceRequest {
    return new DeleteIgnoreDifferenceRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteIgnoreDifferenceRequest | PlainMessage<DeleteIgnoreDifferenceRequest> | undefined, b: DeleteIgnoreDifferenceRequest | PlainMessage<DeleteIgnoreDifferenceRequest> | undefined): boolean {
    return proto3.util.equals(DeleteIgnoreDifferenceRequest, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.CreateClusterRequest
 */
export class CreateClusterRequest extends Message<CreateClusterRequest> {
  /**
   * @generated from field: string parent = 1;
   */
  parent = "";

  /**
   * @generated from field: annealing.delivery.v1.Cluster cluster = 2;
   */
  cluster?: Cluster;

  /**
   * @generated from field: string cluster_id = 3;
   */
  clusterId = "";

  constructor(data?: PartialMessage<CreateClusterRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.CreateClusterRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "parent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "cluster", kind: "message", T: Cluster },
    { no: 3, name: "cluster_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateClusterRequest {
    return new CreateClusterRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateClusterRequest {
    return new CreateClusterRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateClusterRequest {
    return new CreateClusterRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateClusterRequest | PlainMessage<CreateClusterRequest> | undefined, b: CreateClusterRequest | PlainMessage<CreateClusterRequest> | undefined): boolean {
    return proto3.util.equals(CreateClusterRequest, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.GetClusterRequest
 */
export class GetClusterRequest extends Message<GetClusterRequest> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  constructor(data?: PartialMessage<GetClusterRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.GetClusterRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetClusterRequest {
    return new GetClusterRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetClusterRequest {
    return new GetClusterRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetClusterRequest {
    return new GetClusterRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetClusterRequest | PlainMessage<GetClusterRequest> | undefined, b: GetClusterRequest | PlainMessage<GetClusterRequest> | undefined): boolean {
    return proto3.util.equals(GetClusterRequest, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.ListClustersRequest
 */
export class ListClustersRequest extends Message<ListClustersRequest> {
  /**
   * @generated from field: string parent = 1;
   */
  parent = "";

  /**
   * @generated from field: int32 page_size = 2;
   */
  pageSize = 0;

  /**
   * @generated from field: string page_token = 3;
   */
  pageToken = "";

  constructor(data?: PartialMessage<ListClustersRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.ListClustersRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "parent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListClustersRequest {
    return new ListClustersRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListClustersRequest {
    return new ListClustersRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListClustersRequest {
    return new ListClustersRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListClustersRequest | PlainMessage<ListClustersRequest> | undefined, b: ListClustersRequest | PlainMessage<ListClustersRequest> | undefined): boolean {
    return proto3.util.equals(ListClustersRequest, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.ListClustersResponse
 */
export class ListClustersResponse extends Message<ListClustersResponse> {
  /**
   * @generated from field: repeated annealing.delivery.v1.Cluster clusters = 1;
   */
  clusters: Cluster[] = [];

  /**
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = "";

  /**
   * @generated from field: string previous_page_token = 3;
   */
  previousPageToken = "";

  /**
   * @generated from field: int32 total_size = 4;
   */
  totalSize = 0;

  /**
   * @generated from field: int32 start_index = 5;
   */
  startIndex = 0;

  constructor(data?: PartialMessage<ListClustersResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.ListClustersResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "clusters", kind: "message", T: Cluster, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "previous_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "total_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "start_index", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListClustersResponse {
    return new ListClustersResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListClustersResponse {
    return new ListClustersResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListClustersResponse {
    return new ListClustersResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListClustersResponse | PlainMessage<ListClustersResponse> | undefined, b: ListClustersResponse | PlainMessage<ListClustersResponse> | undefined): boolean {
    return proto3.util.equals(ListClustersResponse, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.DeleteClusterRequest
 */
export class DeleteClusterRequest extends Message<DeleteClusterRequest> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  constructor(data?: PartialMessage<DeleteClusterRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.DeleteClusterRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteClusterRequest {
    return new DeleteClusterRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteClusterRequest {
    return new DeleteClusterRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteClusterRequest {
    return new DeleteClusterRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteClusterRequest | PlainMessage<DeleteClusterRequest> | undefined, b: DeleteClusterRequest | PlainMessage<DeleteClusterRequest> | undefined): boolean {
    return proto3.util.equals(DeleteClusterRequest, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.CreateApplicationRequest
 */
export class CreateApplicationRequest extends Message<CreateApplicationRequest> {
  /**
   * @generated from field: string parent = 1;
   */
  parent = "";

  /**
   * @generated from field: annealing.delivery.v1.Application application = 2;
   */
  application?: Application;

  /**
   * @generated from field: string application_id = 3;
   */
  applicationId = "";

  constructor(data?: PartialMessage<CreateApplicationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.CreateApplicationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "parent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "application", kind: "message", T: Application },
    { no: 3, name: "application_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateApplicationRequest {
    return new CreateApplicationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateApplicationRequest {
    return new CreateApplicationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateApplicationRequest {
    return new CreateApplicationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateApplicationRequest | PlainMessage<CreateApplicationRequest> | undefined, b: CreateApplicationRequest | PlainMessage<CreateApplicationRequest> | undefined): boolean {
    return proto3.util.equals(CreateApplicationRequest, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.ListApplicationsRequest
 */
export class ListApplicationsRequest extends Message<ListApplicationsRequest> {
  /**
   * @generated from field: string parent = 1;
   */
  parent = "";

  /**
   * @generated from field: int32 page_size = 2;
   */
  pageSize = 0;

  /**
   * @generated from field: string page_token = 3;
   */
  pageToken = "";

  constructor(data?: PartialMessage<ListApplicationsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.ListApplicationsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "parent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListApplicationsRequest {
    return new ListApplicationsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListApplicationsRequest {
    return new ListApplicationsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListApplicationsRequest {
    return new ListApplicationsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListApplicationsRequest | PlainMessage<ListApplicationsRequest> | undefined, b: ListApplicationsRequest | PlainMessage<ListApplicationsRequest> | undefined): boolean {
    return proto3.util.equals(ListApplicationsRequest, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.ListApplicationsResponse
 */
export class ListApplicationsResponse extends Message<ListApplicationsResponse> {
  /**
   * @generated from field: repeated annealing.delivery.v1.Application applications = 1;
   */
  applications: Application[] = [];

  /**
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = "";

  /**
   * @generated from field: string previous_page_token = 3;
   */
  previousPageToken = "";

  /**
   * @generated from field: int32 total_size = 4;
   */
  totalSize = 0;

  /**
   * @generated from field: int32 start_index = 5;
   */
  startIndex = 0;

  constructor(data?: PartialMessage<ListApplicationsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.ListApplicationsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "applications", kind: "message", T: Application, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "previous_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "total_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "start_index", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListApplicationsResponse {
    return new ListApplicationsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListApplicationsResponse {
    return new ListApplicationsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListApplicationsResponse {
    return new ListApplicationsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListApplicationsResponse | PlainMessage<ListApplicationsResponse> | undefined, b: ListApplicationsResponse | PlainMessage<ListApplicationsResponse> | undefined): boolean {
    return proto3.util.equals(ListApplicationsResponse, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.GetApplicationRequest
 */
export class GetApplicationRequest extends Message<GetApplicationRequest> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  constructor(data?: PartialMessage<GetApplicationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.GetApplicationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetApplicationRequest {
    return new GetApplicationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetApplicationRequest {
    return new GetApplicationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetApplicationRequest {
    return new GetApplicationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetApplicationRequest | PlainMessage<GetApplicationRequest> | undefined, b: GetApplicationRequest | PlainMessage<GetApplicationRequest> | undefined): boolean {
    return proto3.util.equals(GetApplicationRequest, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.UpdateApplicationRequest
 */
export class UpdateApplicationRequest extends Message<UpdateApplicationRequest> {
  /**
   * @generated from field: annealing.delivery.v1.Application application = 1;
   */
  application?: Application;

  /**
   * @generated from field: google.protobuf.FieldMask update_mask = 2;
   */
  updateMask?: FieldMask;

  constructor(data?: PartialMessage<UpdateApplicationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.UpdateApplicationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "application", kind: "message", T: Application },
    { no: 2, name: "update_mask", kind: "message", T: FieldMask },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateApplicationRequest {
    return new UpdateApplicationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateApplicationRequest {
    return new UpdateApplicationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateApplicationRequest {
    return new UpdateApplicationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateApplicationRequest | PlainMessage<UpdateApplicationRequest> | undefined, b: UpdateApplicationRequest | PlainMessage<UpdateApplicationRequest> | undefined): boolean {
    return proto3.util.equals(UpdateApplicationRequest, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.DeleteApplicationRequest
 */
export class DeleteApplicationRequest extends Message<DeleteApplicationRequest> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  constructor(data?: PartialMessage<DeleteApplicationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.DeleteApplicationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteApplicationRequest {
    return new DeleteApplicationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteApplicationRequest {
    return new DeleteApplicationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteApplicationRequest {
    return new DeleteApplicationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteApplicationRequest | PlainMessage<DeleteApplicationRequest> | undefined, b: DeleteApplicationRequest | PlainMessage<DeleteApplicationRequest> | undefined): boolean {
    return proto3.util.equals(DeleteApplicationRequest, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.GetIncarnationRequest
 */
export class GetIncarnationRequest extends Message<GetIncarnationRequest> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  constructor(data?: PartialMessage<GetIncarnationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.GetIncarnationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetIncarnationRequest {
    return new GetIncarnationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetIncarnationRequest {
    return new GetIncarnationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetIncarnationRequest {
    return new GetIncarnationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetIncarnationRequest | PlainMessage<GetIncarnationRequest> | undefined, b: GetIncarnationRequest | PlainMessage<GetIncarnationRequest> | undefined): boolean {
    return proto3.util.equals(GetIncarnationRequest, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.ListIncarnationsRequest
 */
export class ListIncarnationsRequest extends Message<ListIncarnationsRequest> {
  /**
   * @generated from field: string parent = 1;
   */
  parent = "";

  /**
   * @generated from field: int32 page_size = 2;
   */
  pageSize = 0;

  /**
   * @generated from field: string page_token = 3;
   */
  pageToken = "";

  constructor(data?: PartialMessage<ListIncarnationsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.ListIncarnationsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "parent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListIncarnationsRequest {
    return new ListIncarnationsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListIncarnationsRequest {
    return new ListIncarnationsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListIncarnationsRequest {
    return new ListIncarnationsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListIncarnationsRequest | PlainMessage<ListIncarnationsRequest> | undefined, b: ListIncarnationsRequest | PlainMessage<ListIncarnationsRequest> | undefined): boolean {
    return proto3.util.equals(ListIncarnationsRequest, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.ListIncarnationsResponse
 */
export class ListIncarnationsResponse extends Message<ListIncarnationsResponse> {
  /**
   * @generated from field: repeated annealing.delivery.v1.Incarnation incarnations = 1;
   */
  incarnations: Incarnation[] = [];

  /**
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = "";

  /**
   * @generated from field: string previous_page_token = 3;
   */
  previousPageToken = "";

  /**
   * @generated from field: int32 total_size = 4;
   */
  totalSize = 0;

  /**
   * @generated from field: int32 start_index = 5;
   */
  startIndex = 0;

  constructor(data?: PartialMessage<ListIncarnationsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.ListIncarnationsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "incarnations", kind: "message", T: Incarnation, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "previous_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "total_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "start_index", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListIncarnationsResponse {
    return new ListIncarnationsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListIncarnationsResponse {
    return new ListIncarnationsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListIncarnationsResponse {
    return new ListIncarnationsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListIncarnationsResponse | PlainMessage<ListIncarnationsResponse> | undefined, b: ListIncarnationsResponse | PlainMessage<ListIncarnationsResponse> | undefined): boolean {
    return proto3.util.equals(ListIncarnationsResponse, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.GetIncarnationFileRequest
 */
export class GetIncarnationFileRequest extends Message<GetIncarnationFileRequest> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  constructor(data?: PartialMessage<GetIncarnationFileRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.GetIncarnationFileRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetIncarnationFileRequest {
    return new GetIncarnationFileRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetIncarnationFileRequest {
    return new GetIncarnationFileRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetIncarnationFileRequest {
    return new GetIncarnationFileRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetIncarnationFileRequest | PlainMessage<GetIncarnationFileRequest> | undefined, b: GetIncarnationFileRequest | PlainMessage<GetIncarnationFileRequest> | undefined): boolean {
    return proto3.util.equals(GetIncarnationFileRequest, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.ListIncarnationFilesRequest
 */
export class ListIncarnationFilesRequest extends Message<ListIncarnationFilesRequest> {
  /**
   * @generated from field: string parent = 1;
   */
  parent = "";

  constructor(data?: PartialMessage<ListIncarnationFilesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.ListIncarnationFilesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "parent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListIncarnationFilesRequest {
    return new ListIncarnationFilesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListIncarnationFilesRequest {
    return new ListIncarnationFilesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListIncarnationFilesRequest {
    return new ListIncarnationFilesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListIncarnationFilesRequest | PlainMessage<ListIncarnationFilesRequest> | undefined, b: ListIncarnationFilesRequest | PlainMessage<ListIncarnationFilesRequest> | undefined): boolean {
    return proto3.util.equals(ListIncarnationFilesRequest, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.ListIncarnationFilesResponse
 */
export class ListIncarnationFilesResponse extends Message<ListIncarnationFilesResponse> {
  /**
   * @generated from field: repeated annealing.delivery.v1.IncarnationFile incarnation_files = 1;
   */
  incarnationFiles: IncarnationFile[] = [];

  /**
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = "";

  /**
   * @generated from field: string previous_page_token = 3;
   */
  previousPageToken = "";

  /**
   * @generated from field: int32 total_size = 4;
   */
  totalSize = 0;

  /**
   * @generated from field: int32 start_index = 5;
   */
  startIndex = 0;

  constructor(data?: PartialMessage<ListIncarnationFilesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.ListIncarnationFilesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "incarnation_files", kind: "message", T: IncarnationFile, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "previous_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "total_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "start_index", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListIncarnationFilesResponse {
    return new ListIncarnationFilesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListIncarnationFilesResponse {
    return new ListIncarnationFilesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListIncarnationFilesResponse {
    return new ListIncarnationFilesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListIncarnationFilesResponse | PlainMessage<ListIncarnationFilesResponse> | undefined, b: ListIncarnationFilesResponse | PlainMessage<ListIncarnationFilesResponse> | undefined): boolean {
    return proto3.util.equals(ListIncarnationFilesResponse, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.GetIncarnationAssetRequest
 */
export class GetIncarnationAssetRequest extends Message<GetIncarnationAssetRequest> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  constructor(data?: PartialMessage<GetIncarnationAssetRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.GetIncarnationAssetRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetIncarnationAssetRequest {
    return new GetIncarnationAssetRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetIncarnationAssetRequest {
    return new GetIncarnationAssetRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetIncarnationAssetRequest {
    return new GetIncarnationAssetRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetIncarnationAssetRequest | PlainMessage<GetIncarnationAssetRequest> | undefined, b: GetIncarnationAssetRequest | PlainMessage<GetIncarnationAssetRequest> | undefined): boolean {
    return proto3.util.equals(GetIncarnationAssetRequest, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.ListIncarnationAssetsRequest
 */
export class ListIncarnationAssetsRequest extends Message<ListIncarnationAssetsRequest> {
  /**
   * @generated from field: string parent = 1;
   */
  parent = "";

  constructor(data?: PartialMessage<ListIncarnationAssetsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.ListIncarnationAssetsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "parent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListIncarnationAssetsRequest {
    return new ListIncarnationAssetsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListIncarnationAssetsRequest {
    return new ListIncarnationAssetsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListIncarnationAssetsRequest {
    return new ListIncarnationAssetsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListIncarnationAssetsRequest | PlainMessage<ListIncarnationAssetsRequest> | undefined, b: ListIncarnationAssetsRequest | PlainMessage<ListIncarnationAssetsRequest> | undefined): boolean {
    return proto3.util.equals(ListIncarnationAssetsRequest, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.ListIncarnationAssetsResponse
 */
export class ListIncarnationAssetsResponse extends Message<ListIncarnationAssetsResponse> {
  /**
   * @generated from field: repeated annealing.delivery.v1.IncarnationAsset incarnation_assets = 1;
   */
  incarnationAssets: IncarnationAsset[] = [];

  /**
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = "";

  /**
   * @generated from field: string previous_page_token = 3;
   */
  previousPageToken = "";

  /**
   * @generated from field: int32 total_size = 4;
   */
  totalSize = 0;

  /**
   * @generated from field: int32 start_index = 5;
   */
  startIndex = 0;

  constructor(data?: PartialMessage<ListIncarnationAssetsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.ListIncarnationAssetsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "incarnation_assets", kind: "message", T: IncarnationAsset, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "previous_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "total_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "start_index", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListIncarnationAssetsResponse {
    return new ListIncarnationAssetsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListIncarnationAssetsResponse {
    return new ListIncarnationAssetsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListIncarnationAssetsResponse {
    return new ListIncarnationAssetsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListIncarnationAssetsResponse | PlainMessage<ListIncarnationAssetsResponse> | undefined, b: ListIncarnationAssetsResponse | PlainMessage<ListIncarnationAssetsResponse> | undefined): boolean {
    return proto3.util.equals(ListIncarnationAssetsResponse, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.ListAssetsRequest
 */
export class ListAssetsRequest extends Message<ListAssetsRequest> {
  /**
   * @generated from field: string parent = 1;
   */
  parent = "";

  /**
   * @generated from field: int32 page_size = 2;
   */
  pageSize = 0;

  /**
   * @generated from field: string page_token = 3;
   */
  pageToken = "";

  constructor(data?: PartialMessage<ListAssetsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.ListAssetsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "parent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListAssetsRequest {
    return new ListAssetsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListAssetsRequest {
    return new ListAssetsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListAssetsRequest {
    return new ListAssetsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListAssetsRequest | PlainMessage<ListAssetsRequest> | undefined, b: ListAssetsRequest | PlainMessage<ListAssetsRequest> | undefined): boolean {
    return proto3.util.equals(ListAssetsRequest, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.ListAssetsResponse
 */
export class ListAssetsResponse extends Message<ListAssetsResponse> {
  /**
   * @generated from field: repeated annealing.delivery.v1.Asset assets = 1;
   */
  assets: Asset[] = [];

  /**
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = "";

  /**
   * @generated from field: string previous_page_token = 3;
   */
  previousPageToken = "";

  /**
   * @generated from field: int32 total_size = 4;
   */
  totalSize = 0;

  /**
   * @generated from field: int32 start_index = 5;
   */
  startIndex = 0;

  constructor(data?: PartialMessage<ListAssetsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.ListAssetsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "assets", kind: "message", T: Asset, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "previous_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "total_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "start_index", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListAssetsResponse {
    return new ListAssetsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListAssetsResponse {
    return new ListAssetsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListAssetsResponse {
    return new ListAssetsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListAssetsResponse | PlainMessage<ListAssetsResponse> | undefined, b: ListAssetsResponse | PlainMessage<ListAssetsResponse> | undefined): boolean {
    return proto3.util.equals(ListAssetsResponse, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.GetAssetRequest
 */
export class GetAssetRequest extends Message<GetAssetRequest> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  constructor(data?: PartialMessage<GetAssetRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.GetAssetRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAssetRequest {
    return new GetAssetRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAssetRequest {
    return new GetAssetRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAssetRequest {
    return new GetAssetRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetAssetRequest | PlainMessage<GetAssetRequest> | undefined, b: GetAssetRequest | PlainMessage<GetAssetRequest> | undefined): boolean {
    return proto3.util.equals(GetAssetRequest, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.GetRolloutRequest
 */
export class GetRolloutRequest extends Message<GetRolloutRequest> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  constructor(data?: PartialMessage<GetRolloutRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.GetRolloutRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetRolloutRequest {
    return new GetRolloutRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetRolloutRequest {
    return new GetRolloutRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetRolloutRequest {
    return new GetRolloutRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetRolloutRequest | PlainMessage<GetRolloutRequest> | undefined, b: GetRolloutRequest | PlainMessage<GetRolloutRequest> | undefined): boolean {
    return proto3.util.equals(GetRolloutRequest, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.ListRolloutsRequest
 */
export class ListRolloutsRequest extends Message<ListRolloutsRequest> {
  /**
   * @generated from field: string parent = 1;
   */
  parent = "";

  /**
   * @generated from field: int32 page_size = 2;
   */
  pageSize = 0;

  /**
   * @generated from field: string page_token = 3;
   */
  pageToken = "";

  constructor(data?: PartialMessage<ListRolloutsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.ListRolloutsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "parent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListRolloutsRequest {
    return new ListRolloutsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListRolloutsRequest {
    return new ListRolloutsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListRolloutsRequest {
    return new ListRolloutsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListRolloutsRequest | PlainMessage<ListRolloutsRequest> | undefined, b: ListRolloutsRequest | PlainMessage<ListRolloutsRequest> | undefined): boolean {
    return proto3.util.equals(ListRolloutsRequest, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.ListRolloutsResponse
 */
export class ListRolloutsResponse extends Message<ListRolloutsResponse> {
  /**
   * @generated from field: repeated annealing.delivery.v1.Rollout rollouts = 1;
   */
  rollouts: Rollout[] = [];

  /**
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = "";

  /**
   * @generated from field: string previous_page_token = 3;
   */
  previousPageToken = "";

  /**
   * @generated from field: int32 total_size = 4;
   */
  totalSize = 0;

  /**
   * @generated from field: int32 start_index = 5;
   */
  startIndex = 0;

  constructor(data?: PartialMessage<ListRolloutsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.ListRolloutsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "rollouts", kind: "message", T: Rollout, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "previous_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "total_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "start_index", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListRolloutsResponse {
    return new ListRolloutsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListRolloutsResponse {
    return new ListRolloutsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListRolloutsResponse {
    return new ListRolloutsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListRolloutsResponse | PlainMessage<ListRolloutsResponse> | undefined, b: ListRolloutsResponse | PlainMessage<ListRolloutsResponse> | undefined): boolean {
    return proto3.util.equals(ListRolloutsResponse, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.SearchRolloutsRequest
 */
export class SearchRolloutsRequest extends Message<SearchRolloutsRequest> {
  /**
   * @generated from field: string parent = 1;
   */
  parent = "";

  /**
   * @generated from field: int32 page_size = 2;
   */
  pageSize = 0;

  /**
   * @generated from field: string page_token = 3;
   */
  pageToken = "";

  constructor(data?: PartialMessage<SearchRolloutsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.SearchRolloutsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "parent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SearchRolloutsRequest {
    return new SearchRolloutsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SearchRolloutsRequest {
    return new SearchRolloutsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SearchRolloutsRequest {
    return new SearchRolloutsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SearchRolloutsRequest | PlainMessage<SearchRolloutsRequest> | undefined, b: SearchRolloutsRequest | PlainMessage<SearchRolloutsRequest> | undefined): boolean {
    return proto3.util.equals(SearchRolloutsRequest, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.SearchRolloutsResponse
 */
export class SearchRolloutsResponse extends Message<SearchRolloutsResponse> {
  /**
   * @generated from field: repeated annealing.delivery.v1.Rollout rollouts = 1;
   */
  rollouts: Rollout[] = [];

  /**
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = "";

  /**
   * @generated from field: string previous_page_token = 3;
   */
  previousPageToken = "";

  /**
   * @generated from field: int32 total_size = 4;
   */
  totalSize = 0;

  /**
   * @generated from field: int32 start_index = 5;
   */
  startIndex = 0;

  constructor(data?: PartialMessage<SearchRolloutsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.SearchRolloutsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "rollouts", kind: "message", T: Rollout, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "previous_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "total_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "start_index", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SearchRolloutsResponse {
    return new SearchRolloutsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SearchRolloutsResponse {
    return new SearchRolloutsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SearchRolloutsResponse {
    return new SearchRolloutsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SearchRolloutsResponse | PlainMessage<SearchRolloutsResponse> | undefined, b: SearchRolloutsResponse | PlainMessage<SearchRolloutsResponse> | undefined): boolean {
    return proto3.util.equals(SearchRolloutsResponse, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.PauseRolloutRequest
 */
export class PauseRolloutRequest extends Message<PauseRolloutRequest> {
  constructor(data?: PartialMessage<PauseRolloutRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.PauseRolloutRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PauseRolloutRequest {
    return new PauseRolloutRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PauseRolloutRequest {
    return new PauseRolloutRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PauseRolloutRequest {
    return new PauseRolloutRequest().fromJsonString(jsonString, options);
  }

  static equals(a: PauseRolloutRequest | PlainMessage<PauseRolloutRequest> | undefined, b: PauseRolloutRequest | PlainMessage<PauseRolloutRequest> | undefined): boolean {
    return proto3.util.equals(PauseRolloutRequest, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.PauseRolloutResponse
 */
export class PauseRolloutResponse extends Message<PauseRolloutResponse> {
  constructor(data?: PartialMessage<PauseRolloutResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.PauseRolloutResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PauseRolloutResponse {
    return new PauseRolloutResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PauseRolloutResponse {
    return new PauseRolloutResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PauseRolloutResponse {
    return new PauseRolloutResponse().fromJsonString(jsonString, options);
  }

  static equals(a: PauseRolloutResponse | PlainMessage<PauseRolloutResponse> | undefined, b: PauseRolloutResponse | PlainMessage<PauseRolloutResponse> | undefined): boolean {
    return proto3.util.equals(PauseRolloutResponse, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.ResumeRolloutRequest
 */
export class ResumeRolloutRequest extends Message<ResumeRolloutRequest> {
  constructor(data?: PartialMessage<ResumeRolloutRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.ResumeRolloutRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResumeRolloutRequest {
    return new ResumeRolloutRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResumeRolloutRequest {
    return new ResumeRolloutRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResumeRolloutRequest {
    return new ResumeRolloutRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ResumeRolloutRequest | PlainMessage<ResumeRolloutRequest> | undefined, b: ResumeRolloutRequest | PlainMessage<ResumeRolloutRequest> | undefined): boolean {
    return proto3.util.equals(ResumeRolloutRequest, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.ResumeRolloutResponse
 */
export class ResumeRolloutResponse extends Message<ResumeRolloutResponse> {
  constructor(data?: PartialMessage<ResumeRolloutResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.ResumeRolloutResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResumeRolloutResponse {
    return new ResumeRolloutResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResumeRolloutResponse {
    return new ResumeRolloutResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResumeRolloutResponse {
    return new ResumeRolloutResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ResumeRolloutResponse | PlainMessage<ResumeRolloutResponse> | undefined, b: ResumeRolloutResponse | PlainMessage<ResumeRolloutResponse> | undefined): boolean {
    return proto3.util.equals(ResumeRolloutResponse, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.CancelRolloutRequest
 */
export class CancelRolloutRequest extends Message<CancelRolloutRequest> {
  constructor(data?: PartialMessage<CancelRolloutRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.CancelRolloutRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CancelRolloutRequest {
    return new CancelRolloutRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CancelRolloutRequest {
    return new CancelRolloutRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CancelRolloutRequest {
    return new CancelRolloutRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CancelRolloutRequest | PlainMessage<CancelRolloutRequest> | undefined, b: CancelRolloutRequest | PlainMessage<CancelRolloutRequest> | undefined): boolean {
    return proto3.util.equals(CancelRolloutRequest, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.CancelRolloutResponse
 */
export class CancelRolloutResponse extends Message<CancelRolloutResponse> {
  constructor(data?: PartialMessage<CancelRolloutResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.CancelRolloutResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CancelRolloutResponse {
    return new CancelRolloutResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CancelRolloutResponse {
    return new CancelRolloutResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CancelRolloutResponse {
    return new CancelRolloutResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CancelRolloutResponse | PlainMessage<CancelRolloutResponse> | undefined, b: CancelRolloutResponse | PlainMessage<CancelRolloutResponse> | undefined): boolean {
    return proto3.util.equals(CancelRolloutResponse, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.GetRolloutStepRequest
 */
export class GetRolloutStepRequest extends Message<GetRolloutStepRequest> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  constructor(data?: PartialMessage<GetRolloutStepRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.GetRolloutStepRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetRolloutStepRequest {
    return new GetRolloutStepRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetRolloutStepRequest {
    return new GetRolloutStepRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetRolloutStepRequest {
    return new GetRolloutStepRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetRolloutStepRequest | PlainMessage<GetRolloutStepRequest> | undefined, b: GetRolloutStepRequest | PlainMessage<GetRolloutStepRequest> | undefined): boolean {
    return proto3.util.equals(GetRolloutStepRequest, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.ListRolloutStepsRequest
 */
export class ListRolloutStepsRequest extends Message<ListRolloutStepsRequest> {
  /**
   * @generated from field: string parent = 1;
   */
  parent = "";

  /**
   * @generated from field: int32 page_size = 2;
   */
  pageSize = 0;

  /**
   * @generated from field: string page_token = 3;
   */
  pageToken = "";

  constructor(data?: PartialMessage<ListRolloutStepsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.ListRolloutStepsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "parent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListRolloutStepsRequest {
    return new ListRolloutStepsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListRolloutStepsRequest {
    return new ListRolloutStepsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListRolloutStepsRequest {
    return new ListRolloutStepsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListRolloutStepsRequest | PlainMessage<ListRolloutStepsRequest> | undefined, b: ListRolloutStepsRequest | PlainMessage<ListRolloutStepsRequest> | undefined): boolean {
    return proto3.util.equals(ListRolloutStepsRequest, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.ListRolloutStepsResponse
 */
export class ListRolloutStepsResponse extends Message<ListRolloutStepsResponse> {
  /**
   * @generated from field: repeated annealing.delivery.v1.RolloutStep rollout_steps = 1;
   */
  rolloutSteps: RolloutStep[] = [];

  /**
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = "";

  /**
   * @generated from field: string previous_page_token = 3;
   */
  previousPageToken = "";

  /**
   * @generated from field: int32 total_size = 4;
   */
  totalSize = 0;

  /**
   * @generated from field: int32 start_index = 5;
   */
  startIndex = 0;

  constructor(data?: PartialMessage<ListRolloutStepsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.ListRolloutStepsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "rollout_steps", kind: "message", T: RolloutStep, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "previous_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "total_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "start_index", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListRolloutStepsResponse {
    return new ListRolloutStepsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListRolloutStepsResponse {
    return new ListRolloutStepsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListRolloutStepsResponse {
    return new ListRolloutStepsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListRolloutStepsResponse | PlainMessage<ListRolloutStepsResponse> | undefined, b: ListRolloutStepsResponse | PlainMessage<ListRolloutStepsResponse> | undefined): boolean {
    return proto3.util.equals(ListRolloutStepsResponse, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.GetRolloutAssetRequest
 */
export class GetRolloutAssetRequest extends Message<GetRolloutAssetRequest> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  constructor(data?: PartialMessage<GetRolloutAssetRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.GetRolloutAssetRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetRolloutAssetRequest {
    return new GetRolloutAssetRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetRolloutAssetRequest {
    return new GetRolloutAssetRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetRolloutAssetRequest {
    return new GetRolloutAssetRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetRolloutAssetRequest | PlainMessage<GetRolloutAssetRequest> | undefined, b: GetRolloutAssetRequest | PlainMessage<GetRolloutAssetRequest> | undefined): boolean {
    return proto3.util.equals(GetRolloutAssetRequest, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.ListRolloutAssetsRequest
 */
export class ListRolloutAssetsRequest extends Message<ListRolloutAssetsRequest> {
  /**
   * @generated from field: string parent = 1;
   */
  parent = "";

  /**
   * @generated from field: int32 page_size = 2;
   */
  pageSize = 0;

  /**
   * @generated from field: string page_token = 3;
   */
  pageToken = "";

  constructor(data?: PartialMessage<ListRolloutAssetsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.ListRolloutAssetsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "parent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListRolloutAssetsRequest {
    return new ListRolloutAssetsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListRolloutAssetsRequest {
    return new ListRolloutAssetsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListRolloutAssetsRequest {
    return new ListRolloutAssetsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListRolloutAssetsRequest | PlainMessage<ListRolloutAssetsRequest> | undefined, b: ListRolloutAssetsRequest | PlainMessage<ListRolloutAssetsRequest> | undefined): boolean {
    return proto3.util.equals(ListRolloutAssetsRequest, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.ListRolloutAssetsResponse
 */
export class ListRolloutAssetsResponse extends Message<ListRolloutAssetsResponse> {
  /**
   * @generated from field: repeated annealing.delivery.v1.RolloutAsset rollout_assets = 1;
   */
  rolloutAssets: RolloutAsset[] = [];

  /**
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = "";

  /**
   * @generated from field: string previous_page_token = 3;
   */
  previousPageToken = "";

  /**
   * @generated from field: int32 total_size = 4;
   */
  totalSize = 0;

  /**
   * @generated from field: int32 start_index = 5;
   */
  startIndex = 0;

  constructor(data?: PartialMessage<ListRolloutAssetsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.ListRolloutAssetsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "rollout_assets", kind: "message", T: RolloutAsset, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "previous_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "total_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "start_index", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListRolloutAssetsResponse {
    return new ListRolloutAssetsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListRolloutAssetsResponse {
    return new ListRolloutAssetsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListRolloutAssetsResponse {
    return new ListRolloutAssetsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListRolloutAssetsResponse | PlainMessage<ListRolloutAssetsResponse> | undefined, b: ListRolloutAssetsResponse | PlainMessage<ListRolloutAssetsResponse> | undefined): boolean {
    return proto3.util.equals(ListRolloutAssetsResponse, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.ListChartRepositoriesRequest
 */
export class ListChartRepositoriesRequest extends Message<ListChartRepositoriesRequest> {
  /**
   * @generated from field: string parent = 1;
   */
  parent = "";

  /**
   * @generated from field: int32 page_size = 2;
   */
  pageSize = 0;

  /**
   * @generated from field: string page_token = 3;
   */
  pageToken = "";

  constructor(data?: PartialMessage<ListChartRepositoriesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.ListChartRepositoriesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "parent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListChartRepositoriesRequest {
    return new ListChartRepositoriesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListChartRepositoriesRequest {
    return new ListChartRepositoriesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListChartRepositoriesRequest {
    return new ListChartRepositoriesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListChartRepositoriesRequest | PlainMessage<ListChartRepositoriesRequest> | undefined, b: ListChartRepositoriesRequest | PlainMessage<ListChartRepositoriesRequest> | undefined): boolean {
    return proto3.util.equals(ListChartRepositoriesRequest, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.ListChartRepositoriesResponse
 */
export class ListChartRepositoriesResponse extends Message<ListChartRepositoriesResponse> {
  /**
   * @generated from field: repeated annealing.delivery.v1.ChartRepository chart_repositories = 1;
   */
  chartRepositories: ChartRepository[] = [];

  /**
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = "";

  /**
   * @generated from field: string previous_page_token = 3;
   */
  previousPageToken = "";

  /**
   * @generated from field: int32 total_size = 4;
   */
  totalSize = 0;

  /**
   * @generated from field: int32 start_index = 5;
   */
  startIndex = 0;

  constructor(data?: PartialMessage<ListChartRepositoriesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.ListChartRepositoriesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "chart_repositories", kind: "message", T: ChartRepository, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "previous_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "total_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "start_index", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListChartRepositoriesResponse {
    return new ListChartRepositoriesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListChartRepositoriesResponse {
    return new ListChartRepositoriesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListChartRepositoriesResponse {
    return new ListChartRepositoriesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListChartRepositoriesResponse | PlainMessage<ListChartRepositoriesResponse> | undefined, b: ListChartRepositoriesResponse | PlainMessage<ListChartRepositoriesResponse> | undefined): boolean {
    return proto3.util.equals(ListChartRepositoriesResponse, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.GetChartRepositoryRequest
 */
export class GetChartRepositoryRequest extends Message<GetChartRepositoryRequest> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  constructor(data?: PartialMessage<GetChartRepositoryRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.GetChartRepositoryRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetChartRepositoryRequest {
    return new GetChartRepositoryRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetChartRepositoryRequest {
    return new GetChartRepositoryRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetChartRepositoryRequest {
    return new GetChartRepositoryRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetChartRepositoryRequest | PlainMessage<GetChartRepositoryRequest> | undefined, b: GetChartRepositoryRequest | PlainMessage<GetChartRepositoryRequest> | undefined): boolean {
    return proto3.util.equals(GetChartRepositoryRequest, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.CreateChartRepositoryRequest
 */
export class CreateChartRepositoryRequest extends Message<CreateChartRepositoryRequest> {
  /**
   * @generated from field: string parent = 1;
   */
  parent = "";

  /**
   * @generated from field: annealing.delivery.v1.ChartRepository chart_repository = 2;
   */
  chartRepository?: ChartRepository;

  /**
   * @generated from field: string chart_repository_id = 3;
   */
  chartRepositoryId = "";

  constructor(data?: PartialMessage<CreateChartRepositoryRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.CreateChartRepositoryRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "parent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "chart_repository", kind: "message", T: ChartRepository },
    { no: 3, name: "chart_repository_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateChartRepositoryRequest {
    return new CreateChartRepositoryRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateChartRepositoryRequest {
    return new CreateChartRepositoryRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateChartRepositoryRequest {
    return new CreateChartRepositoryRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateChartRepositoryRequest | PlainMessage<CreateChartRepositoryRequest> | undefined, b: CreateChartRepositoryRequest | PlainMessage<CreateChartRepositoryRequest> | undefined): boolean {
    return proto3.util.equals(CreateChartRepositoryRequest, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.DeleteChartRepositoryRequest
 */
export class DeleteChartRepositoryRequest extends Message<DeleteChartRepositoryRequest> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  constructor(data?: PartialMessage<DeleteChartRepositoryRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.DeleteChartRepositoryRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteChartRepositoryRequest {
    return new DeleteChartRepositoryRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteChartRepositoryRequest {
    return new DeleteChartRepositoryRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteChartRepositoryRequest {
    return new DeleteChartRepositoryRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteChartRepositoryRequest | PlainMessage<DeleteChartRepositoryRequest> | undefined, b: DeleteChartRepositoryRequest | PlainMessage<DeleteChartRepositoryRequest> | undefined): boolean {
    return proto3.util.equals(DeleteChartRepositoryRequest, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.ListGitRepositoriesRequest
 */
export class ListGitRepositoriesRequest extends Message<ListGitRepositoriesRequest> {
  /**
   * @generated from field: string parent = 1;
   */
  parent = "";

  /**
   * @generated from field: int32 page_size = 2;
   */
  pageSize = 0;

  /**
   * @generated from field: string page_token = 3;
   */
  pageToken = "";

  constructor(data?: PartialMessage<ListGitRepositoriesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.ListGitRepositoriesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "parent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListGitRepositoriesRequest {
    return new ListGitRepositoriesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListGitRepositoriesRequest {
    return new ListGitRepositoriesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListGitRepositoriesRequest {
    return new ListGitRepositoriesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListGitRepositoriesRequest | PlainMessage<ListGitRepositoriesRequest> | undefined, b: ListGitRepositoriesRequest | PlainMessage<ListGitRepositoriesRequest> | undefined): boolean {
    return proto3.util.equals(ListGitRepositoriesRequest, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.ListGitRepositoriesResponse
 */
export class ListGitRepositoriesResponse extends Message<ListGitRepositoriesResponse> {
  /**
   * @generated from field: repeated annealing.delivery.v1.GitRepository git_repositories = 1;
   */
  gitRepositories: GitRepository[] = [];

  /**
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = "";

  /**
   * @generated from field: string previous_page_token = 3;
   */
  previousPageToken = "";

  /**
   * @generated from field: int32 total_size = 4;
   */
  totalSize = 0;

  /**
   * @generated from field: int32 start_index = 5;
   */
  startIndex = 0;

  constructor(data?: PartialMessage<ListGitRepositoriesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.ListGitRepositoriesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "git_repositories", kind: "message", T: GitRepository, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "previous_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "total_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "start_index", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListGitRepositoriesResponse {
    return new ListGitRepositoriesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListGitRepositoriesResponse {
    return new ListGitRepositoriesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListGitRepositoriesResponse {
    return new ListGitRepositoriesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListGitRepositoriesResponse | PlainMessage<ListGitRepositoriesResponse> | undefined, b: ListGitRepositoriesResponse | PlainMessage<ListGitRepositoriesResponse> | undefined): boolean {
    return proto3.util.equals(ListGitRepositoriesResponse, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.GetGitRepositoryRequest
 */
export class GetGitRepositoryRequest extends Message<GetGitRepositoryRequest> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  constructor(data?: PartialMessage<GetGitRepositoryRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.GetGitRepositoryRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetGitRepositoryRequest {
    return new GetGitRepositoryRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetGitRepositoryRequest {
    return new GetGitRepositoryRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetGitRepositoryRequest {
    return new GetGitRepositoryRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetGitRepositoryRequest | PlainMessage<GetGitRepositoryRequest> | undefined, b: GetGitRepositoryRequest | PlainMessage<GetGitRepositoryRequest> | undefined): boolean {
    return proto3.util.equals(GetGitRepositoryRequest, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.CreateGitRepositoryRequest
 */
export class CreateGitRepositoryRequest extends Message<CreateGitRepositoryRequest> {
  /**
   * @generated from field: string parent = 1;
   */
  parent = "";

  /**
   * @generated from field: annealing.delivery.v1.GitRepository git_repository = 2;
   */
  gitRepository?: GitRepository;

  /**
   * @generated from field: string git_repository_id = 3;
   */
  gitRepositoryId = "";

  constructor(data?: PartialMessage<CreateGitRepositoryRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.CreateGitRepositoryRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "parent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "git_repository", kind: "message", T: GitRepository },
    { no: 3, name: "git_repository_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateGitRepositoryRequest {
    return new CreateGitRepositoryRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateGitRepositoryRequest {
    return new CreateGitRepositoryRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateGitRepositoryRequest {
    return new CreateGitRepositoryRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateGitRepositoryRequest | PlainMessage<CreateGitRepositoryRequest> | undefined, b: CreateGitRepositoryRequest | PlainMessage<CreateGitRepositoryRequest> | undefined): boolean {
    return proto3.util.equals(CreateGitRepositoryRequest, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.DeleteGitRepositoryRequest
 */
export class DeleteGitRepositoryRequest extends Message<DeleteGitRepositoryRequest> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  constructor(data?: PartialMessage<DeleteGitRepositoryRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.DeleteGitRepositoryRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteGitRepositoryRequest {
    return new DeleteGitRepositoryRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteGitRepositoryRequest {
    return new DeleteGitRepositoryRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteGitRepositoryRequest {
    return new DeleteGitRepositoryRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteGitRepositoryRequest | PlainMessage<DeleteGitRepositoryRequest> | undefined, b: DeleteGitRepositoryRequest | PlainMessage<DeleteGitRepositoryRequest> | undefined): boolean {
    return proto3.util.equals(DeleteGitRepositoryRequest, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.ListChartsRequest
 */
export class ListChartsRequest extends Message<ListChartsRequest> {
  /**
   * @generated from field: string parent = 1;
   */
  parent = "";

  /**
   * @generated from field: int32 page_size = 2;
   */
  pageSize = 0;

  /**
   * @generated from field: string page_token = 3;
   */
  pageToken = "";

  constructor(data?: PartialMessage<ListChartsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.ListChartsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "parent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListChartsRequest {
    return new ListChartsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListChartsRequest {
    return new ListChartsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListChartsRequest {
    return new ListChartsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListChartsRequest | PlainMessage<ListChartsRequest> | undefined, b: ListChartsRequest | PlainMessage<ListChartsRequest> | undefined): boolean {
    return proto3.util.equals(ListChartsRequest, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.ListChartsResponse
 */
export class ListChartsResponse extends Message<ListChartsResponse> {
  /**
   * @generated from field: repeated annealing.delivery.v1.Chart charts = 1;
   */
  charts: Chart[] = [];

  /**
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = "";

  /**
   * @generated from field: string previous_page_token = 3;
   */
  previousPageToken = "";

  /**
   * @generated from field: int32 total_size = 4;
   */
  totalSize = 0;

  /**
   * @generated from field: int32 start_index = 5;
   */
  startIndex = 0;

  constructor(data?: PartialMessage<ListChartsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.ListChartsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "charts", kind: "message", T: Chart, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "previous_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "total_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "start_index", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListChartsResponse {
    return new ListChartsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListChartsResponse {
    return new ListChartsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListChartsResponse {
    return new ListChartsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListChartsResponse | PlainMessage<ListChartsResponse> | undefined, b: ListChartsResponse | PlainMessage<ListChartsResponse> | undefined): boolean {
    return proto3.util.equals(ListChartsResponse, a, b);
  }
}

