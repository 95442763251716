import {
  CheckCircleIcon,
  EllipsisHorizontalCircleIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/20/solid';
import { chartRepoStateToString } from '../../helpers/util';

interface ChartRepoStateBadgeProps {
  state: number;
}

export function ChartRepoStateBadge(props: ChartRepoStateBadgeProps) {
  return (
    <span className="flex items-center text-sm text-gray-500">
      {chartRepoStateToString(props.state) === 'Connected' && (
        <CheckCircleIcon
          className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400"
          aria-hidden="true"
        />
      )}
      {chartRepoStateToString(props.state) === 'Disconnected' && (
        <ExclamationCircleIcon
          className="mr-1.5 h-5 w-5 flex-shrink-0 text-red-400"
          aria-hidden="true"
        />
      )}
      {chartRepoStateToString(props.state) === 'Pending' && (
        <EllipsisHorizontalCircleIcon
          className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
          aria-hidden="true"
        />
      )}
      {chartRepoStateToString(props.state)}
    </span>
  );
}
