// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=ts"
// @generated from file annealing/sts/v1/service.proto (package annealing.sts.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CreateSessionRequest, GetUserInfoRequest } from "./service_pb.js";
import { Session, UserInfo } from "./resources_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service annealing.sts.v1.SecurityTokenService
 */
export const SecurityTokenService = {
  typeName: "annealing.sts.v1.SecurityTokenService",
  methods: {
    /**
     * @generated from rpc annealing.sts.v1.SecurityTokenService.CreateSession
     */
    createSession: {
      name: "CreateSession",
      I: CreateSessionRequest,
      O: Session,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc annealing.sts.v1.SecurityTokenService.GetUserInfo
     */
    getUserInfo: {
      name: "GetUserInfo",
      I: GetUserInfoRequest,
      O: UserInfo,
      kind: MethodKind.Unary,
    },
  }
} as const;

