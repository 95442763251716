import { Code, ConnectError } from '@connectrpc/connect';

import {
  Rollout,
  RolloutStep,
} from '../../proto/annealing/delivery/v1/resources_pb';

export function formatIncarnation(value: string): string {
  return `#${value}`;
}

export function formatDuration(duration: number): string {
  const totalSecs = Math.ceil(duration / 1000.0);
  const hours = Math.floor(totalSecs / 3600.0);
  const minutes = Math.floor((totalSecs % 3600.0) / 60.0);
  const seconds = Math.floor((totalSecs % 3600.0) % 60.0);
  if (hours > 0) {
    return `${hours}h ${minutes}m ${seconds}s`;
  }
  if (minutes > 0) {
    return `${minutes}m ${seconds}s`;
  }
  return `${seconds}s`;
}

export function oidcAccessTypeToProto(accessType: string): number {
  switch (accessType) {
    case 'Public':
      return 1;
    case 'Private':
      return 2;
    default:
      return 0;
  }
}

export function oidcAccessTypeToString(accessType: number): string {
  switch (accessType) {
    case 0:
      return 'Unspecified';
    case 1:
      return 'Public';
    case 2:
      return 'Private';
    default:
      return 'Unknown';
  }
}

export function ignoreDifferenceTypeToProto(ignoreType: string): number {
  switch (ignoreType) {
    case 'JsonPointer':
      return 1;
    case 'JqPathExpression':
      return 2;
    case 'ManagedFieldsManager':
      return 3;
    default:
      return 0;
  }
}

export function ignoreDifferenceTypeToString(ignoreType: number): string {
  switch (ignoreType) {
    case 0:
      return 'Unspecified';
    case 1:
      return 'JsonPointer';
    case 2:
      return 'JqPathExpression';
    case 3:
      return 'ManagedFieldsManager';
    default:
      return 'Unknown';
  }
}

export function roleBindingStateToString(state: number): string {
  switch (state) {
    case 0:
      return 'Unspecified';
    case 1:
      return 'Pending';
    case 2:
      return 'Active';
    default:
      return 'Unknown';
  }
}

export function roleToProto(role: string): number {
  switch (role) {
    case 'Owner':
      return 1;
    case 'Editor':
      return 2;
    case 'Viewer':
      return 3;
    case 'Agent':
      return 4;
    case 'Deployer':
      return 5;
    default:
      return 0;
  }
}

export function roleToString(role: number): string {
  switch (role) {
    case 0:
      return 'Unspecified';
    case 1:
      return 'Owner';
    case 2:
      return 'Editor';
    case 3:
      return 'Viewer';
    case 4:
      return 'Agent';
    case 5:
      return 'Deployer';
    default:
      return 'Unknown';
  }
}

export function rolloutStateFinished(state: number): boolean {
  return (
    rolloutStateToString(state) === 'Succeeded' ||
    rolloutStateToString(state) === 'Failed'
  );
}

export function rolloutStateToString(state: number): string {
  switch (state) {
    case 0:
      return 'Unspecified';
    case 1:
      return 'Pending';
    case 2:
      return 'Running';
    case 3:
      return 'Succeeded';
    case 4:
      return 'Failed';
    case 5:
      return 'Pausing';
    case 6:
      return 'Paused';
    case 7:
      return 'Aborting';
    case 8:
      return 'Aborted';
    default:
      return 'Unknown';
  }
}

export function rolloutStateToColor(state: number): string {
  switch (state) {
    case 0:
      return 'bg-gray-100 text-gray-800';
    case 1:
      return 'bg-gray-100 text-gray-800';
    case 2:
      return 'bg-yellow-100 text-yellow-800';
    case 3:
      return 'bg-green-100 text-green-800';
    case 4:
      return 'bg-red-100 text-red-800';
    case 5:
      return 'bg-teal-100 text-teal-800';
    case 6:
      return 'bg-blue-100 text-blue-800';
    case 7:
      return 'bg-yellow-100 text-yellow-800';
    case 8:
      return 'bg-orange-100 text-orange-800';
    default:
      return 'bg-gray-100 text-gray-800';
  }
}

export function rolloutStepKindToString(kind: number): string {
  switch (kind) {
    case 0:
      return 'Unspecified';
    case 1:
      return 'ApplyAction';
    case 2:
      return 'DestroyAction';
    case 3:
      return 'PurgeAction';
    default:
      return 'Unknown';
  }
}

export function rolloutStepStateToString(state: number): string {
  switch (state) {
    case 0:
      return 'Unspecified';
    case 1:
      return 'Pending';
    case 2:
      return 'Running';
    case 3:
      return 'Succeeded';
    case 4:
      return 'Failed';
    default:
      return 'Unknown';
  }
}

export function rolloutStepStateToColor(state: number): string {
  switch (state) {
    case 0:
      return 'bg-gray-100 text-gray-800';
    case 1:
      return 'bg-gray-100 text-gray-800';
    case 2:
      return 'bg-yellow-100 text-yellow-800';
    case 3:
      return 'bg-green-100 text-green-800';
    case 4:
      return 'bg-red-100 text-red-800';
    default:
      return 'bg-gray-100 text-gray-800';
  }
}

export function sshKeyStateToString(state: number): string {
  switch (state) {
    case 0:
      return 'Unspecified';
    case 1:
      return 'Pending';
    case 2:
      return 'Active';
    default:
      return 'Unknown';
  }
}

export function sshKnownHostStateToString(state: number): string {
  switch (state) {
    case 0:
      return 'Unspecified';
    case 1:
      return 'Pending';
    case 2:
      return 'Active';
    default:
      return 'Unknown';
  }
}

export function ignoreDifferenceStateToString(state: number): string {
  switch (state) {
    case 0:
      return 'Unspecified';
    case 1:
      return 'Pending';
    case 2:
      return 'Active';
    default:
      return 'Unknown';
  }
}

export function oidcProviderStateToString(state: number): string {
  switch (state) {
    case 0:
      return 'Unspecified';
    case 1:
      return 'Pending';
    case 2:
      return 'Active';
    case 3:
      return 'Error';
    default:
      return 'Unknown';
  }
}

export function serviceAccountStateToString(state: number): string {
  switch (state) {
    case 0:
      return 'Unspecified';
    case 1:
      return 'Pending';
    case 2:
      return 'Active';
    default:
      return 'Unknown';
  }
}

export function clusterStateToString(state: number): string {
  switch (state) {
    case 0:
      return 'Unspecified';
    case 1:
      return 'Pending';
    case 2:
      return 'Connected';
    case 3:
      return 'Disconnected';
    default:
      return 'Unknown';
  }
}

export function machineStateToString(state: number): string {
  switch (state) {
    case 0:
      return 'Unspecified';
    case 1:
      return 'Creating';
    case 2:
      return 'Updating';
    case 3:
      return 'Deleting';
    case 4:
      return 'Active';
    case 5:
      return 'Failed';
    case 6:
      return 'Degraded';
    default:
      return 'Unknown';
  }
}

export function kubeClusterStateToString(state: number): string {
  switch (state) {
    case 0:
      return 'Unspecified';
    case 1:
      return 'Creating';
    case 2:
      return 'Updating';
    case 3:
      return 'Deleting';
    case 4:
      return 'Active';
    case 5:
      return 'Failed';
    case 6:
      return 'Degraded';
    default:
      return 'Unknown';
  }
}

export function applicationStateToString(state: number): string {
  switch (state) {
    case 0:
      return 'Unspecified';
    case 1:
      return 'Active';
    default:
      return 'Unknown';
  }
}

export function clusterStateToColor(state: number): string {
  switch (state) {
    case 0:
      return 'bg-gray-100 text-gray-800';
    case 1:
      return 'bg-gray-100 text-gray-800';
    case 2:
      return 'bg-green-100 text-green-800';
    case 3:
      return 'bg-red-100 text-red-800';
    default:
      return 'bg-gray-100 text-gray-800';
  }
}

export function objectPinReasonToString(reason: number): string {
  switch (reason) {
    case 0:
      return 'Unspecified';
    case 1:
      return 'Create';
    case 2:
      return 'Fast forward';
    case 3:
      return 'Canary';
    case 4:
      return 'Rollout';
    case 5:
      return 'Rollback';
    case 6:
      return 'Revert';
    case 7:
      return 'Destroy';
    case 8:
      return 'Abandon';
    default:
      return 'Unknown';
  }
}

export function appSourceKindToString(source: number): string {
  switch (source) {
    case 0:
      return '';
    case 1:
      return 'Directory';
    case 2:
      return 'Kustomize';
    case 3:
      return 'Chart';
    default:
      return 'Unknown';
  }
}

export function gitRepoStateToString(source: number): string {
  switch (source) {
    case 0:
      return '';
    case 1:
      return 'Pending';
    case 2:
      return 'Connected';
    case 3:
      return 'Disconnected';
    default:
      return 'Unknown';
  }
}

export function gitRepoStateToColor(state: number): string {
  switch (state) {
    case 0:
      return 'bg-gray-100 text-gray-800';
    case 1:
      return 'bg-gray-100 text-gray-800';
    case 2:
      return 'bg-green-100 text-green-800';
    case 3:
      return 'bg-red-100 text-red-800';
    default:
      return 'bg-gray-100 text-gray-800';
  }
}

export function chartStateToString(source: number): string {
  switch (source) {
    case 0:
      return '';
    case 1:
      return 'Active';
    case 2:
      return 'Removed';
    default:
      return 'Unknown';
  }
}

export function chartRepoStateToString(source: number): string {
  switch (source) {
    case 0:
      return '';
    case 1:
      return 'Pending';
    case 2:
      return 'Connected';
    case 3:
      return 'Disconnected';
    default:
      return 'Unknown';
  }
}

export function chartRepoStateToColor(state: number): string {
  switch (state) {
    case 0:
      return 'bg-gray-100 text-gray-800';
    case 1:
      return 'bg-gray-100 text-gray-800';
    case 2:
      return 'bg-green-100 text-green-800';
    case 3:
      return 'bg-red-100 text-red-800';
    default:
      return 'bg-gray-100 text-gray-800';
  }
}

export function appRevisionStateToString(state: number): string {
  switch (state) {
    case 0:
      return 'Unspecified';
    case 1:
      return 'Pending';
    case 2:
      return 'Rendering';
    case 3:
      return 'Rendered';
    case 4:
      return 'Failed';
    default:
      return 'Unknown';
  }
}

export function appRevisionStateToColor(state: number): string {
  switch (state) {
    case 0:
      return 'bg-gray-100 text-gray-800';
    case 1:
      return 'bg-gray-100 text-gray-800';
    case 2:
      return 'bg-yellow-100 text-yellow-800';
    case 3:
      return 'bg-green-100 text-green-800';
    case 4:
      return 'bg-red-100 text-red-800';
    default:
      return 'bg-gray-100 text-gray-800';
  }
}

export function assetSyncStateToString(state: number): string {
  switch (state) {
    case 0:
      return 'Unspecified';
    case 1:
      return 'OutOfSync';
    case 2:
      return 'Synced';
    case 3:
      return 'Unknown';
    default:
      return 'Unknown';
  }
}

export function assetHealthStateToString(state: number): string {
  switch (state) {
    case 0:
      return 'Unspecified';
    case 1:
      return 'Missing';
    case 2:
      return 'Progressing';
    case 3:
      return 'Healthy';
    case 4:
      return 'Degraded';
    case 5:
      return 'Suspended';
    default:
      return 'Unknown';
  }
}

export function objectStateToString(state: number): string {
  switch (state) {
    case 0:
      return 'Unspecified';
    case 1:
      return 'Pending';
    case 2:
      return 'Reconciling';
    case 3:
      return 'Reconciled';
    case 4:
      return 'Failed';
    case 5:
      return 'Reverted';
    default:
      return 'Unknown';
  }
}

export function objectStateToColor(state: number): string {
  switch (state) {
    case 0:
      return 'bg-gray-100 text-gray-800';
    case 1:
      return 'bg-gray-100 text-gray-800';
    case 2:
      return 'bg-yellow-100 text-yellow-800';
    case 3:
      return 'bg-green-100 text-green-800';
    case 4:
      return 'bg-red-100 text-red-800';
    case 5:
      return 'bg-indigo-100 text-indigo-800';
    default:
      return 'bg-gray-100 text-gray-800';
  }
}

export function capitalize(s: string): string {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

interface RpcResult {
  status: string;
  reason: any;
}

export function isRpcPermissionDeniedResult(res: RpcResult) {
  return res.status === 'rejected' && isRpcPermissionDeniedError(res.reason);
}

export function isRpcPermissionDeniedError(err: any) {
  return err instanceof ConnectError && err.code === Code.PermissionDenied;
}

export function isRpcUnauthenticatedResult(res: RpcResult) {
  return res.status === 'rejected' && isRpcUnauthenticatedError(res.reason);
}

export function isRpcUnauthenticatedError(err: any) {
  return err instanceof ConnectError && err.code === Code.Unauthenticated;
}

export function projectResourceName(projectId: string) {
  return `projects/${projectId}`;
}

export function roleBindingResourceName(
  projectId: string,
  roleBindingId: string,
) {
  return `projects/${projectId}/roleBindings/${roleBindingId}`;
}

export function clusterResourceName(projectId: string, clusterId: string) {
  return `projects/${projectId}/clusters/${clusterId}`;
}

export function machineResourceName(projectId: string, machineId: string) {
  return `projects/${projectId}/machines/${machineId}`;
}

export function operationResourceName(projectId: string, operationId: string) {
  return `projects/${projectId}/operations/${operationId}`;
}

export function appResourceName(projectId: string, appId: string) {
  return `projects/${projectId}/applications/${appId}`;
}

export function appRevisionResourceName(
  projectId: string,
  appId: string,
  revisionId: string,
) {
  return `projects/${projectId}/applications/${appId}/incarnations/${revisionId}`;
}

export function rolloutResourceName(
  projectId: string,
  appId: string,
  rolloutId: string,
) {
  return `projects/${projectId}/applications/${appId}/rollouts/${rolloutId}`;
}

export function chartRepoResourceName(projectId: string, repoId: string) {
  return `projects/${projectId}/chartRepositories/${repoId}`;
}

export function sshKeyResourceName(projectId: string, keyId: string) {
  return `projects/${projectId}/sshKeys/${keyId}`;
}

export function sshKnownHostResourceName(projectId: string, hostId: string) {
  return `projects/${projectId}/sshKnownHosts/${hostId}`;
}

export function gitRepoResourceName(projectId: string, repoId: string) {
  return `projects/${projectId}/gitRepositories/${repoId}`;
}

export function oidcProviderResourceName(
  projectId: string,
  oidcProviderId: string,
) {
  return `projects/${projectId}/identityProviders/${oidcProviderId}`;
}

export function serviceAccountResourceName(
  projectId: string,
  serviceAccountId: string,
) {
  return `projects/${projectId}/serviceAccounts/${serviceAccountId}`;
}

export function ignoreDifferenceResourceName(
  projectId: string,
  ignoreDifferenceId: string,
) {
  return `projects/${projectId}/ignoreDifferences/${ignoreDifferenceId}`;
}

export function objectResourceName(
  projectId: string,
  appId: string,
  clusterId: string,
  apiGroup: string,
  apiKind: string,
  namespaceName: string,
  objectName: string,
) {
  return `projects/${projectId}/applications/${appId}/assets/${clusterId}~${apiGroup}~${apiKind}~${namespaceName}~${objectName}`;
}

export function rolloutDuration(rollout: Rollout) {
  if (!rollout.startTime) {
    return '\u2014';
  }
  if (!rollout.finishTime) {
    return formatDuration(
      new Date().valueOf() - rollout.startTime.toDate().valueOf(),
    );
  }
  return formatDuration(
    rollout.finishTime.toDate().valueOf() -
      rollout.startTime.toDate().valueOf(),
  );
}

export function rolloutPercentage(rollout: Rollout): number {
  if (rollout.totalStepCount === 0) {
    return 0;
  }
  return Math.floor((rollout.finishedStepCount / rollout.totalStepCount) * 100);
}

export function formatDate(d: Date): string {
  return d.toLocaleDateString(undefined, {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZoneName: 'short',
  });
}

export function classNames(...classes: string[]): string {
  return classes.filter(Boolean).join(' ');
}

export function rolloutStepDuration(step: RolloutStep) {
  if (!step.startTime) {
    return '\u2014';
  }
  if (!step.finishTime) {
    return formatDuration(
      new Date().valueOf() - step.startTime.toDate().valueOf(),
    );
  }
  return formatDuration(
    step.finishTime.toDate().valueOf() -
      step.startTime.toDate().valueOf(),
  );
}
