import { Menu, Transition } from '@headlessui/react';
import { ChevronUpDownIcon } from '@heroicons/react/24/solid';
import { Fragment } from 'react';

import { classNames } from '../../helpers/util';
import { useGetUserInfo } from '../../hooks/use_sts_api';
import { routes } from '../../router';

export function UserMenu() {
  const getUserInfoResult = useGetUserInfo();
  const userInfo = getUserInfoResult.data;

  return (
    <Menu
      as="div"
      className="relative mt-2 mb-2 inline-block px-2 text-left">
      {({ open }) => (
        <>
          <div className="space-y-1">
            <Menu.Button className="group w-full rounded-md px-2.5 py-2.5 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-gray-100 dark:border-gray-500 dark:text-white dark:hover:bg-gray-700">
              <span className="flex w-full items-center justify-between">
                <span className="flex min-w-0 items-center justify-between space-x-3">
                  <img
                    className="inline-block h-9 w-9 rounded-md"
                    src={userInfo.picture}
                    alt=""
                  />
                  <span className="flex min-w-0 flex-1 flex-col">
                    <span className="truncate text-sm text-left text-gray-900 dark:text-white">
                      {userInfo.name}
                    </span>
                    <span className="truncate text-xs text-left text-gray-400">
                      User
                    </span>
                  </span>
                </span>
                <ChevronUpDownIcon
                  className="h-5 w-5 ml-3 flex-shrink-0 text-gray-400 group-hover:text-gray-500 dark:group-hover:text-gray-300"
                  aria-hidden="true"
                />
              </span>
            </Menu.Button>
          </div>
          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="w-56 absolute left-0 right-0 z-10 mb-1 origin-top rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:divide-gray-500 dark:bg-gray-800 dark:ring-gray-500"
              anchor={{ to: 'top' }}
            >
              <div>
                <Menu.Item>
                  {({ active }) => (
                    <a
                      href={routes.logout().link.href}
                      className={classNames(
                        active
                          ? 'bg-gray-100 text-gray-900 dark:bg-gray-700 dark:text-white'
                          : 'text-gray-700 dark:text-gray-400',
                        'block px-4 py-2 text-sm',
                      )}
                    >
                      Sign out
                    </a>
                  )}
                </Menu.Item>
              </div>
              <div>
                <Menu.Item>
                  {({ active }) => (
                    <a
                      {...routes.user().link}
                      className={classNames(
                        active
                          ? 'bg-gray-100 text-gray-900 dark:bg-gray-700 dark:text-white'
                          : 'text-gray-700 dark:text-gray-400',
                        'block px-4 py-2 text-sm',
                      )}
                    >
                      Settings
                    </a>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
}
