import { Disclosure } from '@headlessui/react';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import {
  ChartBarIcon,
  Cog8ToothIcon,
  KeyIcon,
  RectangleGroupIcon,
  ServerStackIcon,
} from '@heroicons/react/24/outline';

import { classNames } from '../../helpers/util';
import { routes, useRoute } from '../../router';

export interface ProjectNavigationProps {
  projectId: string;
}

export function ProjectNavigation(props: ProjectNavigationProps) {
  const route = useRoute();
  const navigation = [
    {
      name: 'Dashboard',
      icon: ChartBarIcon,
      route: routes.projectDetail({ projectId: props.projectId }),
    },
    {
      name: 'Delivery',
      icon: RectangleGroupIcon,
      children: [
        {
          name: 'Applications',
          route: routes.applicationList({ projectId: props.projectId }),
        },
        {
          name: 'Clusters',
          route: routes.clusterList({ projectId: props.projectId }),
        },
        {
          name: 'Git repositories',
          route: routes.gitRepositoryList({ projectId: props.projectId }),
        },
        {
          name: 'Chart repositories',
          route: routes.chartRepositoryList({ projectId: props.projectId }),
        },
        //{
        //  name: 'OCI registries',
        //  href: '#',
        //  icon: CubeIcon,
        //  external: false,
        //  link: Link,
        //},
        {
          name: 'SSH keys',
          route: routes.sshKeyList({ projectId: props.projectId }),
        },
        {
          name: 'SSH known hosts',
          route: routes.sshKnownHostList({ projectId: props.projectId }),
        },
        {
          name: 'Ignore differences',
          route: routes.ignoreDifferenceList({ projectId: props.projectId }),
        },
        //{
        //  name: 'Known type fields',
        //  href: '#',
        //  icon: PuzzleIcon,
        //  external: false,
        //  link: Link,
        //},
        //{
        //  name: 'Custom health checks',
        //  href: '#',
        //  icon: HeartIcon,
        //  external: false,
        //  link: Link,
        //},
        //{
        //  name: 'Custom actions',
        //  href: '#',
        //  icon: LightningBoltIcon,
        //  external: false,
        //  link: Link,
        //},
        //{
        //  name: 'Notification providers',
        //  href: '#',
        //  icon: BellIcon,
        //  external: false,
        //  link: Link,
        //},
        //{
        //  name: 'Sync windows',
        //  href: '#',
        //  icon: CalendarIcon,
        //  external: false,
        //  link: Link,
        //},
        //{
        //  name: 'Audit log',
        //  href: auditLogLink(props.projectId),
        //  icon: MenuAlt2Icon,
        //  link: Link,
        //},
      ],
    },
    //{
    //  name: 'Security & Governance',
    //  items: [
    //    {
    //      name: 'Policy controls',
    //      href: '#',
    //      icon: ScaleIcon,
    //      external: false,
    //      link: Link,
    //    },
    //    {
    //      name: 'Service meshes',
    //      href: '#',
    //      icon: GlobeIcon,
    //      external: false,
    //      link: Link,
    //    },
    //    {
    //      name: 'Threat detection',
    //      href: '#',
    //      icon: FireIcon,
    //      external: false,
    //      link: Link,
    //    },
    //    {
    //      name: 'Software supply chain',
    //      href: '#',
    //      icon: LinkIcon,
    //      external: false,
    //      link: Link,
    //    },
    //  ],
    //},
    {
      name: 'Compute',
      icon: ServerStackIcon,
      children: [
        {
          name: 'Clusters',
          route: routes.computeClusterList({ projectId: props.projectId }),
        },
        {
          name: 'Machines',
          route: routes.computeMachineList({ projectId: props.projectId }),
        },
        {
          name: 'Operations',
          route: routes.computeOperationList({ projectId: props.projectId }),
        },
      ],
    },
    {
      name: 'IAM',
      icon: KeyIcon,
      children: [
        {
          name: 'Role bindings',
          route: routes.roleBindingList({ projectId: props.projectId }),
        },
        {
          name: 'OIDC providers',
          route: routes.oidcProviderList({ projectId: props.projectId }),
        },
        {
          name: 'Service accounts',
          route: routes.serviceAccountList({ projectId: props.projectId }),
        },
      ],
    },
    {
      name: 'Settings',
      icon: Cog8ToothIcon,
      route: routes.projectSettings({ projectId: props.projectId }),
    },
  ];

  return (
    <>
      <div className="my-2 flex flex-grow flex-col">
        <nav className="flex-1 space-y-1 px-2" aria-label="Sidebar">
          {navigation.map((item) =>
            !item.children ?
              (
                <div key={item.name}>
                  <a
                    {...item.route.link}
                    className={classNames(
                      item.route.name === route.name
                        ? 'bg-gray-100 text-gray-900 dark:bg-gray-900 dark:text-white'
                        : 'text-gray-600 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white',
                      'group flex items-center rounded-md px-2 py-2 text-sm font-medium',
                    )}
                  >
                    <item.icon
                      className={classNames(
                        item.route.name === route.name
                          ? 'text-gray-500 dark:text-gray-300'
                          : 'text-gray-400 group-hover:text-gray-500 dark:text-gray-400 dark:group-hover:text-gray-300',
                        'mr-3 h-6 w-6 flex-shrink-0',
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </a>
                </div>
              ) :
              (
                <Disclosure
                  as="div"
                  key={item.name}
                  defaultOpen={item.children.some(
                    (subItem) => subItem.route.name === route.name,
                  )}
                  className="space-y-1"
                >
                  {({ open }) => (
                    <>
                      <Disclosure.Button
                        className={classNames(
                          (item.route && (item.route.name === route.name))
                            ? 'bg-gray-100 text-gray-900 dark:bg-gray-900 dark:text-white'
                            : 'text-gray-600 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white',
                          'group flex w-full items-center rounded-md py-2 px-2 text-left text-sm font-medium focus:outline-none focus:ring-2 focus:ring-indigo-500',
                        )}
                      >
                        <item.icon
                          className="mr-3 h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500 dark:group-hover:text-gray-300"
                          aria-hidden="true"
                        />
                        <span className="flex-1">{item.name}</span>
                        <ChevronRightIcon
                          className={classNames(
                            open ? 'rotate-90' : '',
                            'ml-3 h-5 w-5 flex-shrink-0 transform font-semibold transition-colors duration-150 ease-in-out group-hover:text-gray-900 dark:group-hover:text-white',
                          )}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="space-y-1">
                        {item.children.map((subItem) => (
                          <Disclosure.Button
                            key={subItem.name}
                            as="a"
                            {...subItem.route.link}
                            className={classNames(
                              subItem.route.name === route.name
                                ? 'bg-gray-100 text-gray-900 dark:bg-gray-900 dark:text-white'
                                : 'text-gray-600 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white',
                              'group flex w-full items-center rounded-md py-2 pl-11 pr-2 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-indigo-500',
                            )}
                          >
                            {subItem.name}
                          </Disclosure.Button>
                        ))}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              )
          )}
        </nav>
      </div>
    </>
  );
}
