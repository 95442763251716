// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=ts"
// @generated from file annealing/iam/v1/service.proto (package annealing.iam.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CreateIdentityProviderRequest, CreateRoleBindingRequest, CreateServiceAccountRequest, DeleteIdentityProviderRequest, DeleteRoleBindingRequest, DeleteServiceAccountRequest, GetIdentityProviderRequest, GetRoleBindingRequest, GetServiceAccountRequest, ListIdentityProvidersRequest, ListIdentityProvidersResponse, ListRoleBindingsRequest, ListRoleBindingsResponse, ListServiceAccountsRequest, ListServiceAccountsResponse } from "./service_pb.js";
import { IdentityProvider, RoleBinding, ServiceAccount } from "./resources_pb.js";
import { Empty, MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service annealing.iam.v1.IamService
 */
export const IamService = {
  typeName: "annealing.iam.v1.IamService",
  methods: {
    /**
     * @generated from rpc annealing.iam.v1.IamService.GetIdentityProvider
     */
    getIdentityProvider: {
      name: "GetIdentityProvider",
      I: GetIdentityProviderRequest,
      O: IdentityProvider,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc annealing.iam.v1.IamService.ListIdentityProviders
     */
    listIdentityProviders: {
      name: "ListIdentityProviders",
      I: ListIdentityProvidersRequest,
      O: ListIdentityProvidersResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc annealing.iam.v1.IamService.CreateIdentityProvider
     */
    createIdentityProvider: {
      name: "CreateIdentityProvider",
      I: CreateIdentityProviderRequest,
      O: IdentityProvider,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc annealing.iam.v1.IamService.DeleteIdentityProvider
     */
    deleteIdentityProvider: {
      name: "DeleteIdentityProvider",
      I: DeleteIdentityProviderRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc annealing.iam.v1.IamService.GetRoleBinding
     */
    getRoleBinding: {
      name: "GetRoleBinding",
      I: GetRoleBindingRequest,
      O: RoleBinding,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc annealing.iam.v1.IamService.ListRoleBindings
     */
    listRoleBindings: {
      name: "ListRoleBindings",
      I: ListRoleBindingsRequest,
      O: ListRoleBindingsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc annealing.iam.v1.IamService.CreateRoleBinding
     */
    createRoleBinding: {
      name: "CreateRoleBinding",
      I: CreateRoleBindingRequest,
      O: RoleBinding,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc annealing.iam.v1.IamService.DeleteRoleBinding
     */
    deleteRoleBinding: {
      name: "DeleteRoleBinding",
      I: DeleteRoleBindingRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc annealing.iam.v1.IamService.GetServiceAccount
     */
    getServiceAccount: {
      name: "GetServiceAccount",
      I: GetServiceAccountRequest,
      O: ServiceAccount,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc annealing.iam.v1.IamService.ListServiceAccounts
     */
    listServiceAccounts: {
      name: "ListServiceAccounts",
      I: ListServiceAccountsRequest,
      O: ListServiceAccountsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc annealing.iam.v1.IamService.CreateServiceAccount
     */
    createServiceAccount: {
      name: "CreateServiceAccount",
      I: CreateServiceAccountRequest,
      O: ServiceAccount,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc annealing.iam.v1.IamService.DeleteServiceAccount
     */
    deleteServiceAccount: {
      name: "DeleteServiceAccount",
      I: DeleteServiceAccountRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
  }
} as const;

