// @generated by protoc-gen-es v1.8.0 with parameter "target=ts"
// @generated from file annealing/delivery/v1/enums.proto (package annealing.delivery.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum annealing.delivery.v1.SshKeyState
 */
export enum SshKeyState {
  /**
   * @generated from enum value: SSH_KEY_STATE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: SSH_KEY_STATE_PENDING = 1;
   */
  PENDING = 1,

  /**
   * @generated from enum value: SSH_KEY_STATE_ACTIVE = 2;
   */
  ACTIVE = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(SshKeyState)
proto3.util.setEnumType(SshKeyState, "annealing.delivery.v1.SshKeyState", [
  { no: 0, name: "SSH_KEY_STATE_UNSPECIFIED" },
  { no: 1, name: "SSH_KEY_STATE_PENDING" },
  { no: 2, name: "SSH_KEY_STATE_ACTIVE" },
]);

/**
 * @generated from enum annealing.delivery.v1.SshKnownHostState
 */
export enum SshKnownHostState {
  /**
   * @generated from enum value: SSH_KNOWN_HOST_STATE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: SSH_KNOWN_HOST_STATE_PENDING = 1;
   */
  PENDING = 1,

  /**
   * @generated from enum value: SSH_KNOWN_HOST_STATE_ACTIVE = 2;
   */
  ACTIVE = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(SshKnownHostState)
proto3.util.setEnumType(SshKnownHostState, "annealing.delivery.v1.SshKnownHostState", [
  { no: 0, name: "SSH_KNOWN_HOST_STATE_UNSPECIFIED" },
  { no: 1, name: "SSH_KNOWN_HOST_STATE_PENDING" },
  { no: 2, name: "SSH_KNOWN_HOST_STATE_ACTIVE" },
]);

/**
 * @generated from enum annealing.delivery.v1.IgnoreDifferenceState
 */
export enum IgnoreDifferenceState {
  /**
   * @generated from enum value: IGNORE_DIFFERENCE_STATE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: IGNORE_DIFFERENCE_STATE_PENDING = 1;
   */
  PENDING = 1,

  /**
   * @generated from enum value: IGNORE_DIFFERENCE_STATE_ACTIVE = 2;
   */
  ACTIVE = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(IgnoreDifferenceState)
proto3.util.setEnumType(IgnoreDifferenceState, "annealing.delivery.v1.IgnoreDifferenceState", [
  { no: 0, name: "IGNORE_DIFFERENCE_STATE_UNSPECIFIED" },
  { no: 1, name: "IGNORE_DIFFERENCE_STATE_PENDING" },
  { no: 2, name: "IGNORE_DIFFERENCE_STATE_ACTIVE" },
]);

/**
 * @generated from enum annealing.delivery.v1.IgnoreDifferenceType
 */
export enum IgnoreDifferenceType {
  /**
   * @generated from enum value: IGNORE_DIFFERENCE_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: IGNORE_DIFFERENCE_TYPE_JSON_POINTER = 1;
   */
  JSON_POINTER = 1,

  /**
   * @generated from enum value: IGNORE_DIFFERENCE_TYPE_JQ_PATH_EXPRESSION = 2;
   */
  JQ_PATH_EXPRESSION = 2,

  /**
   * @generated from enum value: IGNORE_DIFFERENCE_TYPE_MANAGED_FIELDS_MANAGER = 3;
   */
  MANAGED_FIELDS_MANAGER = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(IgnoreDifferenceType)
proto3.util.setEnumType(IgnoreDifferenceType, "annealing.delivery.v1.IgnoreDifferenceType", [
  { no: 0, name: "IGNORE_DIFFERENCE_TYPE_UNSPECIFIED" },
  { no: 1, name: "IGNORE_DIFFERENCE_TYPE_JSON_POINTER" },
  { no: 2, name: "IGNORE_DIFFERENCE_TYPE_JQ_PATH_EXPRESSION" },
  { no: 3, name: "IGNORE_DIFFERENCE_TYPE_MANAGED_FIELDS_MANAGER" },
]);

/**
 * @generated from enum annealing.delivery.v1.ClusterState
 */
export enum ClusterState {
  /**
   * @generated from enum value: CLUSTER_STATE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: CLUSTER_STATE_PENDING = 1;
   */
  PENDING = 1,

  /**
   * @generated from enum value: CLUSTER_STATE_CONNECTED = 2;
   */
  CONNECTED = 2,

  /**
   * @generated from enum value: CLUSTER_STATE_DISCONNECTED = 3;
   */
  DISCONNECTED = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(ClusterState)
proto3.util.setEnumType(ClusterState, "annealing.delivery.v1.ClusterState", [
  { no: 0, name: "CLUSTER_STATE_UNSPECIFIED" },
  { no: 1, name: "CLUSTER_STATE_PENDING" },
  { no: 2, name: "CLUSTER_STATE_CONNECTED" },
  { no: 3, name: "CLUSTER_STATE_DISCONNECTED" },
]);

/**
 * @generated from enum annealing.delivery.v1.ApplicationState
 */
export enum ApplicationState {
  /**
   * @generated from enum value: APPLICATION_STATE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: APPLICATION_STATE_ACTIVE = 1;
   */
  ACTIVE = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(ApplicationState)
proto3.util.setEnumType(ApplicationState, "annealing.delivery.v1.ApplicationState", [
  { no: 0, name: "APPLICATION_STATE_UNSPECIFIED" },
  { no: 1, name: "APPLICATION_STATE_ACTIVE" },
]);

/**
 * @generated from enum annealing.delivery.v1.IncarnationState
 */
export enum IncarnationState {
  /**
   * @generated from enum value: INCARNATION_STATE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: INCARNATION_STATE_PENDING = 1;
   */
  PENDING = 1,

  /**
   * @generated from enum value: INCARNATION_STATE_RENDERING = 2;
   */
  RENDERING = 2,

  /**
   * @generated from enum value: INCARNATION_STATE_RENDERED = 3;
   */
  RENDERED = 3,

  /**
   * @generated from enum value: INCARNATION_STATE_FAILED = 4;
   */
  FAILED = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(IncarnationState)
proto3.util.setEnumType(IncarnationState, "annealing.delivery.v1.IncarnationState", [
  { no: 0, name: "INCARNATION_STATE_UNSPECIFIED" },
  { no: 1, name: "INCARNATION_STATE_PENDING" },
  { no: 2, name: "INCARNATION_STATE_RENDERING" },
  { no: 3, name: "INCARNATION_STATE_RENDERED" },
  { no: 4, name: "INCARNATION_STATE_FAILED" },
]);

/**
 * @generated from enum annealing.delivery.v1.GitRepositoryState
 */
export enum GitRepositoryState {
  /**
   * @generated from enum value: GIT_REPOSITORY_STATE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: GIT_REPOSITORY_STATE_PENDING = 1;
   */
  PENDING = 1,

  /**
   * @generated from enum value: GIT_REPOSITORY_STATE_CONNECTED = 2;
   */
  CONNECTED = 2,

  /**
   * @generated from enum value: GIT_REPOSITORY_STATE_DISCONNECTED = 3;
   */
  DISCONNECTED = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(GitRepositoryState)
proto3.util.setEnumType(GitRepositoryState, "annealing.delivery.v1.GitRepositoryState", [
  { no: 0, name: "GIT_REPOSITORY_STATE_UNSPECIFIED" },
  { no: 1, name: "GIT_REPOSITORY_STATE_PENDING" },
  { no: 2, name: "GIT_REPOSITORY_STATE_CONNECTED" },
  { no: 3, name: "GIT_REPOSITORY_STATE_DISCONNECTED" },
]);

/**
 * @generated from enum annealing.delivery.v1.ChartRepositoryState
 */
export enum ChartRepositoryState {
  /**
   * @generated from enum value: CHART_REPOSITORY_STATE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: CHART_REPOSITORY_STATE_PENDING = 1;
   */
  PENDING = 1,

  /**
   * @generated from enum value: CHART_REPOSITORY_STATE_CONNECTED = 2;
   */
  CONNECTED = 2,

  /**
   * @generated from enum value: CHART_REPOSITORY_STATE_DISCONNECTED = 3;
   */
  DISCONNECTED = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(ChartRepositoryState)
proto3.util.setEnumType(ChartRepositoryState, "annealing.delivery.v1.ChartRepositoryState", [
  { no: 0, name: "CHART_REPOSITORY_STATE_UNSPECIFIED" },
  { no: 1, name: "CHART_REPOSITORY_STATE_PENDING" },
  { no: 2, name: "CHART_REPOSITORY_STATE_CONNECTED" },
  { no: 3, name: "CHART_REPOSITORY_STATE_DISCONNECTED" },
]);

/**
 * @generated from enum annealing.delivery.v1.ChartState
 */
export enum ChartState {
  /**
   * @generated from enum value: CHART_STATE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: CHART_STATE_ACTIVE = 1;
   */
  ACTIVE = 1,

  /**
   * @generated from enum value: CHART_STATE_REMOVED = 2;
   */
  REMOVED = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(ChartState)
proto3.util.setEnumType(ChartState, "annealing.delivery.v1.ChartState", [
  { no: 0, name: "CHART_STATE_UNSPECIFIED" },
  { no: 1, name: "CHART_STATE_ACTIVE" },
  { no: 2, name: "CHART_STATE_REMOVED" },
]);

/**
 * AssetPinReason represents the reason for the spec change.
 *
 * @generated from enum annealing.delivery.v1.AssetPinReason
 */
export enum AssetPinReason {
  /**
   * @generated from enum value: ASSET_PIN_REASON_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: ASSET_PIN_REASON_CREATE = 1;
   */
  CREATE = 1,

  /**
   * @generated from enum value: ASSET_PIN_REASON_FAST_FORWARD = 2;
   */
  FAST_FORWARD = 2,

  /**
   * @generated from enum value: ASSET_PIN_REASON_CANARY = 3;
   */
  CANARY = 3,

  /**
   * @generated from enum value: ASSET_PIN_REASON_ROLLOUT = 4;
   */
  ROLLOUT = 4,

  /**
   * @generated from enum value: ASSET_PIN_REASON_ROLLBACK = 5;
   */
  ROLLBACK = 5,

  /**
   * @generated from enum value: ASSET_PIN_REASON_REVERT = 6;
   */
  REVERT = 6,

  /**
   * @generated from enum value: ASSET_PIN_REASON_DESTROY = 7;
   */
  DESTROY = 7,

  /**
   * @generated from enum value: ASSET_PIN_REASON_ABANDON = 8;
   */
  ABANDON = 8,
}
// Retrieve enum metadata with: proto3.getEnumType(AssetPinReason)
proto3.util.setEnumType(AssetPinReason, "annealing.delivery.v1.AssetPinReason", [
  { no: 0, name: "ASSET_PIN_REASON_UNSPECIFIED" },
  { no: 1, name: "ASSET_PIN_REASON_CREATE" },
  { no: 2, name: "ASSET_PIN_REASON_FAST_FORWARD" },
  { no: 3, name: "ASSET_PIN_REASON_CANARY" },
  { no: 4, name: "ASSET_PIN_REASON_ROLLOUT" },
  { no: 5, name: "ASSET_PIN_REASON_ROLLBACK" },
  { no: 6, name: "ASSET_PIN_REASON_REVERT" },
  { no: 7, name: "ASSET_PIN_REASON_DESTROY" },
  { no: 8, name: "ASSET_PIN_REASON_ABANDON" },
]);

/**
 * @generated from enum annealing.delivery.v1.SyncState
 */
export enum SyncState {
  /**
   * @generated from enum value: SYNC_STATE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: SYNC_STATE_OUT_OF_SYNC = 1;
   */
  OUT_OF_SYNC = 1,

  /**
   * @generated from enum value: SYNC_STATE_SYNCED = 2;
   */
  SYNCED = 2,

  /**
   * @generated from enum value: SYNC_STATE_UNKNOWN = 3;
   */
  UNKNOWN = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(SyncState)
proto3.util.setEnumType(SyncState, "annealing.delivery.v1.SyncState", [
  { no: 0, name: "SYNC_STATE_UNSPECIFIED" },
  { no: 1, name: "SYNC_STATE_OUT_OF_SYNC" },
  { no: 2, name: "SYNC_STATE_SYNCED" },
  { no: 3, name: "SYNC_STATE_UNKNOWN" },
]);

/**
 * @generated from enum annealing.delivery.v1.HealthState
 */
export enum HealthState {
  /**
   * @generated from enum value: HEALTH_STATE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: HEALTH_STATE_MISSING = 1;
   */
  MISSING = 1,

  /**
   * @generated from enum value: HEALTH_STATE_PROGRESSING = 2;
   */
  PROGRESSING = 2,

  /**
   * @generated from enum value: HEALTH_STATE_HEALTHY = 3;
   */
  HEALTHY = 3,

  /**
   * @generated from enum value: HEALTH_STATE_DEGRADED = 4;
   */
  DEGRADED = 4,

  /**
   * @generated from enum value: HEALTH_STATE_SUSPENDED = 5;
   */
  SUSPENDED = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(HealthState)
proto3.util.setEnumType(HealthState, "annealing.delivery.v1.HealthState", [
  { no: 0, name: "HEALTH_STATE_UNSPECIFIED" },
  { no: 1, name: "HEALTH_STATE_MISSING" },
  { no: 2, name: "HEALTH_STATE_PROGRESSING" },
  { no: 3, name: "HEALTH_STATE_HEALTHY" },
  { no: 4, name: "HEALTH_STATE_DEGRADED" },
  { no: 5, name: "HEALTH_STATE_SUSPENDED" },
]);

/**
 * @generated from enum annealing.delivery.v1.AssetState
 */
export enum AssetState {
  /**
   * @generated from enum value: ASSET_STATE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: ASSET_STATE_PENDING = 1;
   */
  PENDING = 1,

  /**
   * @generated from enum value: ASSET_STATE_RECONCILING = 2;
   */
  RECONCILING = 2,

  /**
   * @generated from enum value: ASSET_STATE_RECONCILED = 3;
   */
  RECONCILED = 3,

  /**
   * @generated from enum value: ASSET_STATE_FAILED = 4;
   */
  FAILED = 4,

  /**
   * @generated from enum value: ASSET_STATE_REVERTED = 5;
   */
  REVERTED = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(AssetState)
proto3.util.setEnumType(AssetState, "annealing.delivery.v1.AssetState", [
  { no: 0, name: "ASSET_STATE_UNSPECIFIED" },
  { no: 1, name: "ASSET_STATE_PENDING" },
  { no: 2, name: "ASSET_STATE_RECONCILING" },
  { no: 3, name: "ASSET_STATE_RECONCILED" },
  { no: 4, name: "ASSET_STATE_FAILED" },
  { no: 5, name: "ASSET_STATE_REVERTED" },
]);

/**
 * @generated from enum annealing.delivery.v1.AssetEventType
 */
export enum AssetEventType {
  /**
   * @generated from enum value: ASSET_EVENT_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: ASSET_EVENT_TYPE_PUSH_START = 1;
   */
  PUSH_START = 1,

  /**
   * @generated from enum value: ASSET_EVENT_TYPE_PUSH_END = 2;
   */
  PUSH_END = 2,

  /**
   * @generated from enum value: ASSET_EVENT_TYPE_NO_DIFF = 3;
   */
  NO_DIFF = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(AssetEventType)
proto3.util.setEnumType(AssetEventType, "annealing.delivery.v1.AssetEventType", [
  { no: 0, name: "ASSET_EVENT_TYPE_UNSPECIFIED" },
  { no: 1, name: "ASSET_EVENT_TYPE_PUSH_START" },
  { no: 2, name: "ASSET_EVENT_TYPE_PUSH_END" },
  { no: 3, name: "ASSET_EVENT_TYPE_NO_DIFF" },
]);

/**
 * @generated from enum annealing.delivery.v1.RolloutState
 */
export enum RolloutState {
  /**
   * @generated from enum value: ROLLOUT_STATE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: ROLLOUT_STATE_PENDING = 1;
   */
  PENDING = 1,

  /**
   * @generated from enum value: ROLLOUT_STATE_RUNNING = 2;
   */
  RUNNING = 2,

  /**
   * @generated from enum value: ROLLOUT_STATE_SUCCEEDED = 3;
   */
  SUCCEEDED = 3,

  /**
   * @generated from enum value: ROLLOUT_STATE_FAILED = 4;
   */
  FAILED = 4,

  /**
   * @generated from enum value: ROLLOUT_STATE_PAUSING = 5;
   */
  PAUSING = 5,

  /**
   * @generated from enum value: ROLLOUT_STATE_PAUSED = 6;
   */
  PAUSED = 6,

  /**
   * @generated from enum value: ROLLOUT_STATE_ABORTING = 7;
   */
  ABORTING = 7,

  /**
   * @generated from enum value: ROLLOUT_STATE_ABORTED = 8;
   */
  ABORTED = 8,
}
// Retrieve enum metadata with: proto3.getEnumType(RolloutState)
proto3.util.setEnumType(RolloutState, "annealing.delivery.v1.RolloutState", [
  { no: 0, name: "ROLLOUT_STATE_UNSPECIFIED" },
  { no: 1, name: "ROLLOUT_STATE_PENDING" },
  { no: 2, name: "ROLLOUT_STATE_RUNNING" },
  { no: 3, name: "ROLLOUT_STATE_SUCCEEDED" },
  { no: 4, name: "ROLLOUT_STATE_FAILED" },
  { no: 5, name: "ROLLOUT_STATE_PAUSING" },
  { no: 6, name: "ROLLOUT_STATE_PAUSED" },
  { no: 7, name: "ROLLOUT_STATE_ABORTING" },
  { no: 8, name: "ROLLOUT_STATE_ABORTED" },
]);

/**
 * @generated from enum annealing.delivery.v1.RolloutStepKind
 */
export enum RolloutStepKind {
  /**
   * @generated from enum value: ROLLOUT_STEP_KIND_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: ROLLOUT_STEP_KIND_APPLY_ACTION = 1;
   */
  APPLY_ACTION = 1,

  /**
   * @generated from enum value: ROLLOUT_STEP_KIND_DESTROY_ACTION = 2;
   */
  DESTROY_ACTION = 2,

  /**
   * @generated from enum value: ROLLOUT_STEP_KIND_PURGE_ACTION = 3;
   */
  PURGE_ACTION = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(RolloutStepKind)
proto3.util.setEnumType(RolloutStepKind, "annealing.delivery.v1.RolloutStepKind", [
  { no: 0, name: "ROLLOUT_STEP_KIND_UNSPECIFIED" },
  { no: 1, name: "ROLLOUT_STEP_KIND_APPLY_ACTION" },
  { no: 2, name: "ROLLOUT_STEP_KIND_DESTROY_ACTION" },
  { no: 3, name: "ROLLOUT_STEP_KIND_PURGE_ACTION" },
]);

/**
 * @generated from enum annealing.delivery.v1.RolloutStepState
 */
export enum RolloutStepState {
  /**
   * @generated from enum value: ROLLOUT_STEP_STATE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: ROLLOUT_STEP_STATE_PENDING = 1;
   */
  PENDING = 1,

  /**
   * @generated from enum value: ROLLOUT_STEP_STATE_RUNNING = 2;
   */
  RUNNING = 2,

  /**
   * @generated from enum value: ROLLOUT_STEP_STATE_SUCCEEDED = 3;
   */
  SUCCEEDED = 3,

  /**
   * @generated from enum value: ROLLOUT_STEP_STATE_FAILED = 4;
   */
  FAILED = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(RolloutStepState)
proto3.util.setEnumType(RolloutStepState, "annealing.delivery.v1.RolloutStepState", [
  { no: 0, name: "ROLLOUT_STEP_STATE_UNSPECIFIED" },
  { no: 1, name: "ROLLOUT_STEP_STATE_PENDING" },
  { no: 2, name: "ROLLOUT_STEP_STATE_RUNNING" },
  { no: 3, name: "ROLLOUT_STEP_STATE_SUCCEEDED" },
  { no: 4, name: "ROLLOUT_STEP_STATE_FAILED" },
]);

