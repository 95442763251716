import { useForm } from 'react-hook-form';
import { type Route } from 'type-route';

import { projectResourceName } from '../../helpers/util';
import { createChartRepository } from '../../hooks/use_delivery_api';
import { routes } from '../../router';
import { Breadcrumbs } from '../core/breadcrumbs';
import { createToast } from '../core/toast';

interface ChartRepoCreateProps {
  route: Route<typeof routes.chartRepositoryCreate>;
}

interface ChartRepoCreateFormData {
  name: string;
  uri: string;
}

export function ChartRepoCreate(props: ChartRepoCreateProps) {
  const { route } = props;
  const { register, handleSubmit } = useForm<ChartRepoCreateFormData>();

  const parent = projectResourceName(route.params.projectId);

  const onSubmit = async (data: ChartRepoCreateFormData) => {
    try {
      await createChartRepository({
        parent: parent,
        chartRepository: {
          name: '',
          uri: data.uri,
          state: 0,
        },
        chartRepositoryId: data.name,
      });
      createToast(`Chart repository "${data.name}" created.`);
      routes.chartRepositoryDetail({
        projectId: route.params.projectId,
        chartRepositoryId: data.name,
      }).push();
    } catch (err) {
      createToast(`Error creating chart repository: ${err}`);
    }
  };

  const breadcrumbs = [
    {
      name: 'Chart repositories',
      link: routes.chartRepositoryList({
        projectId: route.params.projectId,
      }).link,
      current: true,
    },
  ];

  return (
    <div>
      <Breadcrumbs pages={breadcrumbs} />
      <div className="mt-2 md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h1 className="text-2xl font-medium text-gray-900">
            New chart repository
          </h1>
        </div>
      </div>
      <p className="mt-2 max-w-xl text-sm text-gray-500">
        A chart repository can be added to your project to allow Helm charts to
        be used in applications.{' '}
        <a
          href="#"
          className="font-medium text-indigo-600 hover:text-indigo-500"
        >
          Learn more
        </a>
        .
      </p>
      <form
        className="max-w-xl space-y-8 divide-y divide-gray-200"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="mt-6 space-y-8">
          <div className="space-y-6">
            <div>
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                Repository name
              </label>
              <div className="mt-1">
                <input
                  {...register('name')}
                  type="text"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
            </div>
            <div className="sm:col-span-5">
              <label
                htmlFor="url"
                className="block text-sm font-medium text-gray-700"
              >
                URL
              </label>
              <div className="mt-1">
                <input
                  {...register('uri')}
                  type="text"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="pt-5">
          <div className="flex justify-end">
            <a
              {...routes.chartRepositoryList({
                projectId: route.params.projectId,
              }).link}
              role="button"
              className="rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Cancel
            </a>
            <button
              type="submit"
              className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Attach
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
