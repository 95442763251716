// @generated by protoc-gen-es v1.8.0 with parameter "target=ts"
// @generated from file annealing/delivery/v1/resources.proto (package annealing.delivery.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";
import { AssetEventType, AssetPinReason, ChartRepositoryState, ChartState, ClusterState, GitRepositoryState, HealthState, IgnoreDifferenceState, IgnoreDifferenceType, IncarnationState, RolloutState, RolloutStepKind, RolloutStepState, SshKeyState, SshKnownHostState, SyncState } from "./enums_pb.js";

/**
 * User represents a person who can perform actions.
 *
 * @generated from message annealing.delivery.v1.User
 */
export class User extends Message<User> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string full_name = 2;
   */
  fullName = "";

  /**
   * @generated from field: annealing.delivery.v1.GoogleUser google_user = 3;
   */
  googleUser?: GoogleUser;

  constructor(data?: PartialMessage<User>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.User";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "full_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "google_user", kind: "message", T: GoogleUser },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): User {
    return new User().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): User {
    return new User().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): User {
    return new User().fromJsonString(jsonString, options);
  }

  static equals(a: User | PlainMessage<User> | undefined, b: User | PlainMessage<User> | undefined): boolean {
    return proto3.util.equals(User, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.SshKey
 */
export class SshKey extends Message<SshKey> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: annealing.delivery.v1.SshKeyState state = 2;
   */
  state = SshKeyState.UNSPECIFIED;

  /**
   * @generated from field: string algorithm = 3;
   */
  algorithm = "";

  /**
   * @generated from field: string fingerprint_sha256 = 4;
   */
  fingerprintSha256 = "";

  /**
   * @generated from field: string public_key = 5;
   */
  publicKey = "";

  /**
   * @generated from field: google.protobuf.Timestamp create_time = 6;
   */
  createTime?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp update_time = 7;
   */
  updateTime?: Timestamp;

  constructor(data?: PartialMessage<SshKey>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.SshKey";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "state", kind: "enum", T: proto3.getEnumType(SshKeyState) },
    { no: 3, name: "algorithm", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "fingerprint_sha256", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "public_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "create_time", kind: "message", T: Timestamp },
    { no: 7, name: "update_time", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SshKey {
    return new SshKey().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SshKey {
    return new SshKey().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SshKey {
    return new SshKey().fromJsonString(jsonString, options);
  }

  static equals(a: SshKey | PlainMessage<SshKey> | undefined, b: SshKey | PlainMessage<SshKey> | undefined): boolean {
    return proto3.util.equals(SshKey, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.SshKnownHost
 */
export class SshKnownHost extends Message<SshKnownHost> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: annealing.delivery.v1.SshKnownHostState state = 2;
   */
  state = SshKnownHostState.UNSPECIFIED;

  /**
   * @generated from field: string hostname = 3;
   */
  hostname = "";

  /**
   * @generated from field: string algorithm = 4;
   */
  algorithm = "";

  /**
   * @generated from field: string fingerprint_sha256 = 5;
   */
  fingerprintSha256 = "";

  /**
   * @generated from field: google.protobuf.Timestamp create_time = 6;
   */
  createTime?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp update_time = 7;
   */
  updateTime?: Timestamp;

  constructor(data?: PartialMessage<SshKnownHost>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.SshKnownHost";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "state", kind: "enum", T: proto3.getEnumType(SshKnownHostState) },
    { no: 3, name: "hostname", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "algorithm", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "fingerprint_sha256", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "create_time", kind: "message", T: Timestamp },
    { no: 7, name: "update_time", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SshKnownHost {
    return new SshKnownHost().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SshKnownHost {
    return new SshKnownHost().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SshKnownHost {
    return new SshKnownHost().fromJsonString(jsonString, options);
  }

  static equals(a: SshKnownHost | PlainMessage<SshKnownHost> | undefined, b: SshKnownHost | PlainMessage<SshKnownHost> | undefined): boolean {
    return proto3.util.equals(SshKnownHost, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.IgnoreDifference
 */
export class IgnoreDifference extends Message<IgnoreDifference> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: annealing.delivery.v1.IgnoreDifferenceState state = 2;
   */
  state = IgnoreDifferenceState.UNSPECIFIED;

  /**
   * @generated from field: annealing.delivery.v1.IgnoreDifferenceType type = 3;
   */
  type = IgnoreDifferenceType.UNSPECIFIED;

  /**
   * @generated from field: string value = 4;
   */
  value = "";

  /**
   * @generated from field: string object_group = 5;
   */
  objectGroup = "";

  /**
   * @generated from field: string object_kind = 6;
   */
  objectKind = "";

  /**
   * @generated from field: google.protobuf.Timestamp create_time = 7;
   */
  createTime?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp update_time = 8;
   */
  updateTime?: Timestamp;

  constructor(data?: PartialMessage<IgnoreDifference>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.IgnoreDifference";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "state", kind: "enum", T: proto3.getEnumType(IgnoreDifferenceState) },
    { no: 3, name: "type", kind: "enum", T: proto3.getEnumType(IgnoreDifferenceType) },
    { no: 4, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "object_group", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "object_kind", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "create_time", kind: "message", T: Timestamp },
    { no: 8, name: "update_time", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): IgnoreDifference {
    return new IgnoreDifference().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): IgnoreDifference {
    return new IgnoreDifference().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): IgnoreDifference {
    return new IgnoreDifference().fromJsonString(jsonString, options);
  }

  static equals(a: IgnoreDifference | PlainMessage<IgnoreDifference> | undefined, b: IgnoreDifference | PlainMessage<IgnoreDifference> | undefined): boolean {
    return proto3.util.equals(IgnoreDifference, a, b);
  }
}

/**
 * Cluster represents a Kubernetes cluster.
 *
 * @generated from message annealing.delivery.v1.Cluster
 */
export class Cluster extends Message<Cluster> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string version = 2;
   */
  version = "";

  /**
   * @generated from field: string kube_system_uid = 3;
   */
  kubeSystemUid = "";

  /**
   * @generated from field: int32 asset_count = 4;
   */
  assetCount = 0;

  /**
   * @generated from field: annealing.delivery.v1.ClusterState state = 5;
   */
  state = ClusterState.UNSPECIFIED;

  /**
   * @generated from field: google.protobuf.Timestamp create_time = 6;
   */
  createTime?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp update_time = 7;
   */
  updateTime?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp refresh_time = 8;
   */
  refreshTime?: Timestamp;

  /**
   * @generated from field: string distribution = 9;
   */
  distribution = "";

  constructor(data?: PartialMessage<Cluster>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.Cluster";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "kube_system_uid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "asset_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "state", kind: "enum", T: proto3.getEnumType(ClusterState) },
    { no: 6, name: "create_time", kind: "message", T: Timestamp },
    { no: 7, name: "update_time", kind: "message", T: Timestamp },
    { no: 8, name: "refresh_time", kind: "message", T: Timestamp },
    { no: 9, name: "distribution", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Cluster {
    return new Cluster().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Cluster {
    return new Cluster().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Cluster {
    return new Cluster().fromJsonString(jsonString, options);
  }

  static equals(a: Cluster | PlainMessage<Cluster> | undefined, b: Cluster | PlainMessage<Cluster> | undefined): boolean {
    return proto3.util.equals(Cluster, a, b);
  }
}

/**
 * Asset represents an object in a cluster.
 *
 * @generated from message annealing.delivery.v1.Asset
 */
export class Asset extends Message<Asset> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string revision = 2;
   */
  revision = "";

  /**
   * @generated from field: annealing.delivery.v1.AssetPinReason pin_reason = 3;
   */
  pinReason = AssetPinReason.UNSPECIFIED;

  /**
   * @generated from field: string api_version = 4;
   */
  apiVersion = "";

  /**
   * @generated from field: annealing.delivery.v1.SyncState sync_state = 5;
   */
  syncState = SyncState.UNSPECIFIED;

  /**
   * @generated from field: annealing.delivery.v1.HealthState health_state = 6;
   */
  healthState = HealthState.UNSPECIFIED;

  /**
   * @generated from field: string payload = 7;
   */
  payload = "";

  /**
   * @generated from field: google.protobuf.Timestamp create_time = 8;
   */
  createTime?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp update_time = 9;
   */
  updateTime?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp refresh_time = 10;
   */
  refreshTime?: Timestamp;

  constructor(data?: PartialMessage<Asset>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.Asset";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "revision", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "pin_reason", kind: "enum", T: proto3.getEnumType(AssetPinReason) },
    { no: 4, name: "api_version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "sync_state", kind: "enum", T: proto3.getEnumType(SyncState) },
    { no: 6, name: "health_state", kind: "enum", T: proto3.getEnumType(HealthState) },
    { no: 7, name: "payload", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "create_time", kind: "message", T: Timestamp },
    { no: 9, name: "update_time", kind: "message", T: Timestamp },
    { no: 10, name: "refresh_time", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Asset {
    return new Asset().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Asset {
    return new Asset().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Asset {
    return new Asset().fromJsonString(jsonString, options);
  }

  static equals(a: Asset | PlainMessage<Asset> | undefined, b: Asset | PlainMessage<Asset> | undefined): boolean {
    return proto3.util.equals(Asset, a, b);
  }
}

/**
 * Application represents a collection of objects that are versioned
 * and deployed together.
 *
 * @generated from message annealing.delivery.v1.Application
 */
export class Application extends Message<Application> {
  /**
   * The name of the application.
   *
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * The sync state of the application.
   *
   * @generated from field: annealing.delivery.v1.SyncState sync_state = 2;
   */
  syncState = SyncState.UNSPECIFIED;

  /**
   * The health state of the application.
   *
   * @generated from field: annealing.delivery.v1.HealthState health_state = 3;
   */
  healthState = HealthState.UNSPECIFIED;

  /**
   * The source configuration of the application.
   *
   * @generated from field: annealing.delivery.v1.ApplicationSource source = 4;
   */
  source?: ApplicationSource;

  /**
   * The target configuration of the application.
   *
   * @generated from field: annealing.delivery.v1.ApplicationTarget target = 5;
   */
  target?: ApplicationTarget;

  /**
   * Output only. The number of objects associated with the application.
   *
   * @generated from field: int32 asset_count = 6;
   */
  assetCount = 0;

  /**
   * @generated from field: string desired_incarnation = 7;
   */
  desiredIncarnation = "";

  /**
   * @generated from field: string target_incarnation = 8;
   */
  targetIncarnation = "";

  /**
   * Output only. The time when the application was created.
   *
   * @generated from field: google.protobuf.Timestamp create_time = 9;
   */
  createTime?: Timestamp;

  /**
   * Output only. The time when the application was updated.
   *
   * @generated from field: google.protobuf.Timestamp update_time = 10;
   */
  updateTime?: Timestamp;

  /**
   * Output only. The time when the application was refreshed.
   *
   * @generated from field: google.protobuf.Timestamp refresh_time = 11;
   */
  refreshTime?: Timestamp;

  constructor(data?: PartialMessage<Application>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.Application";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "sync_state", kind: "enum", T: proto3.getEnumType(SyncState) },
    { no: 3, name: "health_state", kind: "enum", T: proto3.getEnumType(HealthState) },
    { no: 4, name: "source", kind: "message", T: ApplicationSource },
    { no: 5, name: "target", kind: "message", T: ApplicationTarget },
    { no: 6, name: "asset_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "desired_incarnation", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "target_incarnation", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "create_time", kind: "message", T: Timestamp },
    { no: 10, name: "update_time", kind: "message", T: Timestamp },
    { no: 11, name: "refresh_time", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Application {
    return new Application().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Application {
    return new Application().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Application {
    return new Application().fromJsonString(jsonString, options);
  }

  static equals(a: Application | PlainMessage<Application> | undefined, b: Application | PlainMessage<Application> | undefined): boolean {
    return proto3.util.equals(Application, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.ApplicationSource
 */
export class ApplicationSource extends Message<ApplicationSource> {
  /**
   * @generated from field: annealing.delivery.v1.GitSource git = 1;
   */
  git?: GitSource;

  /**
   * @generated from field: annealing.delivery.v1.ChartSource chart = 2;
   */
  chart?: ChartSource;

  constructor(data?: PartialMessage<ApplicationSource>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.ApplicationSource";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "git", kind: "message", T: GitSource },
    { no: 2, name: "chart", kind: "message", T: ChartSource },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ApplicationSource {
    return new ApplicationSource().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ApplicationSource {
    return new ApplicationSource().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ApplicationSource {
    return new ApplicationSource().fromJsonString(jsonString, options);
  }

  static equals(a: ApplicationSource | PlainMessage<ApplicationSource> | undefined, b: ApplicationSource | PlainMessage<ApplicationSource> | undefined): boolean {
    return proto3.util.equals(ApplicationSource, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.ApplicationTarget
 */
export class ApplicationTarget extends Message<ApplicationTarget> {
  /**
   * The cluster to deploy to.
   *
   * @generated from field: string cluster = 1;
   */
  cluster = "";

  /**
   * The namespace to use by default.
   *
   * @generated from field: string namespace = 2;
   */
  namespace = "";

  constructor(data?: PartialMessage<ApplicationTarget>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.ApplicationTarget";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "cluster", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "namespace", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ApplicationTarget {
    return new ApplicationTarget().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ApplicationTarget {
    return new ApplicationTarget().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ApplicationTarget {
    return new ApplicationTarget().fromJsonString(jsonString, options);
  }

  static equals(a: ApplicationTarget | PlainMessage<ApplicationTarget> | undefined, b: ApplicationTarget | PlainMessage<ApplicationTarget> | undefined): boolean {
    return proto3.util.equals(ApplicationTarget, a, b);
  }
}

/**
 * Incarnation represents a configuration snapshot for a application.
 * Incarnations are immutable.
 *
 * @generated from message annealing.delivery.v1.Incarnation
 */
export class Incarnation extends Message<Incarnation> {
  /**
   * The name of the incarnation.
   *
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * Output only. The state of the incarnation.
   *
   * @generated from field: annealing.delivery.v1.IncarnationState state = 2;
   */
  state = IncarnationState.UNSPECIFIED;

  /**
   * Output only. The number of files.
   *
   * @generated from field: int32 file_count = 3;
   */
  fileCount = 0;

  /**
   * Output only. The number of objects.
   *
   * @generated from field: int32 asset_count = 4;
   */
  assetCount = 0;

  /**
   * Output only. The tool used to render the incarnation.
   *
   * @generated from field: string tool = 5;
   */
  tool = "";

  /**
   * Output only. The fingerprint of all objects.
   *
   * @generated from field: string fingerprint = 6;
   */
  fingerprint = "";

  /**
   * The source of the incarnation.
   *
   * @generated from field: annealing.delivery.v1.IncarnationSource source = 7;
   */
  source?: IncarnationSource;

  /**
   * The target of the incarnation.
   *
   * @generated from field: annealing.delivery.v1.IncarnationTarget target = 8;
   */
  target?: IncarnationTarget;

  /**
   * Output only. The time the incarnation was created.
   *
   * @generated from field: google.protobuf.Timestamp create_time = 9;
   */
  createTime?: Timestamp;

  /**
   * Output only. The time the incarnation was updated.
   *
   * @generated from field: google.protobuf.Timestamp update_time = 10;
   */
  updateTime?: Timestamp;

  constructor(data?: PartialMessage<Incarnation>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.Incarnation";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "state", kind: "enum", T: proto3.getEnumType(IncarnationState) },
    { no: 3, name: "file_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "asset_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "tool", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "fingerprint", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "source", kind: "message", T: IncarnationSource },
    { no: 8, name: "target", kind: "message", T: IncarnationTarget },
    { no: 9, name: "create_time", kind: "message", T: Timestamp },
    { no: 10, name: "update_time", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Incarnation {
    return new Incarnation().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Incarnation {
    return new Incarnation().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Incarnation {
    return new Incarnation().fromJsonString(jsonString, options);
  }

  static equals(a: Incarnation | PlainMessage<Incarnation> | undefined, b: Incarnation | PlainMessage<Incarnation> | undefined): boolean {
    return proto3.util.equals(Incarnation, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.IncarnationSource
 */
export class IncarnationSource extends Message<IncarnationSource> {
  /**
   * @generated from field: annealing.delivery.v1.GitSource git = 1;
   */
  git?: GitSource;

  /**
   * @generated from field: annealing.delivery.v1.ChartSource chart = 2;
   */
  chart?: ChartSource;

  constructor(data?: PartialMessage<IncarnationSource>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.IncarnationSource";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "git", kind: "message", T: GitSource },
    { no: 2, name: "chart", kind: "message", T: ChartSource },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): IncarnationSource {
    return new IncarnationSource().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): IncarnationSource {
    return new IncarnationSource().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): IncarnationSource {
    return new IncarnationSource().fromJsonString(jsonString, options);
  }

  static equals(a: IncarnationSource | PlainMessage<IncarnationSource> | undefined, b: IncarnationSource | PlainMessage<IncarnationSource> | undefined): boolean {
    return proto3.util.equals(IncarnationSource, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.IncarnationTarget
 */
export class IncarnationTarget extends Message<IncarnationTarget> {
  /**
   * The cluster group to deploy to.
   *
   * @generated from field: string cluster_group = 1;
   */
  clusterGroup = "";

  /**
   * The namespace to use by default.
   *
   * @generated from field: string namespace = 2;
   */
  namespace = "";

  constructor(data?: PartialMessage<IncarnationTarget>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.IncarnationTarget";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "cluster_group", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "namespace", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): IncarnationTarget {
    return new IncarnationTarget().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): IncarnationTarget {
    return new IncarnationTarget().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): IncarnationTarget {
    return new IncarnationTarget().fromJsonString(jsonString, options);
  }

  static equals(a: IncarnationTarget | PlainMessage<IncarnationTarget> | undefined, b: IncarnationTarget | PlainMessage<IncarnationTarget> | undefined): boolean {
    return proto3.util.equals(IncarnationTarget, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.GitSource
 */
export class GitSource extends Message<GitSource> {
  /**
   * The repository containing application configuration.
   *
   * @generated from field: string repository = 1;
   */
  repository = "";

  /**
   * The ref of the repository. Can be either a branch or tag.
   *
   * @generated from field: string revision = 2;
   */
  revision = "";

  /**
   * The path within the repository.
   *
   * @generated from field: string path = 3;
   */
  path = "";

  /**
   * Output only. The latest commit in the branch.
   *
   * @generated from field: string revision_commit = 4;
   */
  revisionCommit = "";

  /**
   * Output only. The latest commit in the path.
   *
   * @generated from field: string path_commit = 5;
   */
  pathCommit = "";

  constructor(data?: PartialMessage<GitSource>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.GitSource";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "repository", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "revision", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "revision_commit", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "path_commit", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GitSource {
    return new GitSource().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GitSource {
    return new GitSource().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GitSource {
    return new GitSource().fromJsonString(jsonString, options);
  }

  static equals(a: GitSource | PlainMessage<GitSource> | undefined, b: GitSource | PlainMessage<GitSource> | undefined): boolean {
    return proto3.util.equals(GitSource, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.ChartSource
 */
export class ChartSource extends Message<ChartSource> {
  /**
   * The chart repository containing application configuration.
   *
   * @generated from field: string repository = 1;
   */
  repository = "";

  /**
   * The name of the chart.
   *
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * The semantic version of the chart.
   *
   * @generated from field: string semantic_version = 3;
   */
  semanticVersion = "";

  /**
   * Output only. The exact version of the chart.
   *
   * @generated from field: string exact_version = 4;
   */
  exactVersion = "";

  constructor(data?: PartialMessage<ChartSource>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.ChartSource";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "repository", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "semantic_version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "exact_version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ChartSource {
    return new ChartSource().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ChartSource {
    return new ChartSource().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ChartSource {
    return new ChartSource().fromJsonString(jsonString, options);
  }

  static equals(a: ChartSource | PlainMessage<ChartSource> | undefined, b: ChartSource | PlainMessage<ChartSource> | undefined): boolean {
    return proto3.util.equals(ChartSource, a, b);
  }
}

/**
 * GoogleUser represents a linked Google account.
 *
 * @generated from message annealing.delivery.v1.GoogleUser
 */
export class GoogleUser extends Message<GoogleUser> {
  /**
   * @generated from field: string email = 1;
   */
  email = "";

  constructor(data?: PartialMessage<GoogleUser>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.GoogleUser";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GoogleUser {
    return new GoogleUser().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GoogleUser {
    return new GoogleUser().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GoogleUser {
    return new GoogleUser().fromJsonString(jsonString, options);
  }

  static equals(a: GoogleUser | PlainMessage<GoogleUser> | undefined, b: GoogleUser | PlainMessage<GoogleUser> | undefined): boolean {
    return proto3.util.equals(GoogleUser, a, b);
  }
}

/**
 * IncarnationFile represents a point-in-time snapshot of a file.
 *
 * @generated from message annealing.delivery.v1.IncarnationFile
 */
export class IncarnationFile extends Message<IncarnationFile> {
  /**
   * The name of the file.
   *
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * The path of the file.
   *
   * @generated from field: string path = 2;
   */
  path = "";

  /**
   * The content of the file.
   *
   * @generated from field: string payload = 3;
   */
  payload = "";

  constructor(data?: PartialMessage<IncarnationFile>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.IncarnationFile";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "payload", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): IncarnationFile {
    return new IncarnationFile().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): IncarnationFile {
    return new IncarnationFile().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): IncarnationFile {
    return new IncarnationFile().fromJsonString(jsonString, options);
  }

  static equals(a: IncarnationFile | PlainMessage<IncarnationFile> | undefined, b: IncarnationFile | PlainMessage<IncarnationFile> | undefined): boolean {
    return proto3.util.equals(IncarnationFile, a, b);
  }
}

/**
 * IncarnationAsset represents a point-in-time snapshot of an object.
 *
 * @generated from message annealing.delivery.v1.IncarnationAsset
 */
export class IncarnationAsset extends Message<IncarnationAsset> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string api_version = 2;
   */
  apiVersion = "";

  /**
   * @generated from field: string payload = 3;
   */
  payload = "";

  constructor(data?: PartialMessage<IncarnationAsset>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.IncarnationAsset";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "api_version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "payload", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): IncarnationAsset {
    return new IncarnationAsset().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): IncarnationAsset {
    return new IncarnationAsset().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): IncarnationAsset {
    return new IncarnationAsset().fromJsonString(jsonString, options);
  }

  static equals(a: IncarnationAsset | PlainMessage<IncarnationAsset> | undefined, b: IncarnationAsset | PlainMessage<IncarnationAsset> | undefined): boolean {
    return proto3.util.equals(IncarnationAsset, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.AssetEvent
 */
export class AssetEvent extends Message<AssetEvent> {
  /**
   * @generated from field: string object = 1;
   */
  object = "";

  /**
   * @generated from field: annealing.delivery.v1.AssetEventType event_type = 2;
   */
  eventType = AssetEventType.UNSPECIFIED;

  /**
   * @generated from field: google.protobuf.Timestamp event_time = 3;
   */
  eventTime?: Timestamp;

  constructor(data?: PartialMessage<AssetEvent>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.AssetEvent";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "object", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "event_type", kind: "enum", T: proto3.getEnumType(AssetEventType) },
    { no: 3, name: "event_time", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AssetEvent {
    return new AssetEvent().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AssetEvent {
    return new AssetEvent().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AssetEvent {
    return new AssetEvent().fromJsonString(jsonString, options);
  }

  static equals(a: AssetEvent | PlainMessage<AssetEvent> | undefined, b: AssetEvent | PlainMessage<AssetEvent> | undefined): boolean {
    return proto3.util.equals(AssetEvent, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.Rollout
 */
export class Rollout extends Message<Rollout> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string incarnation = 2;
   */
  incarnation = "";

  /**
   * @generated from field: int32 asset_count = 3;
   */
  assetCount = 0;

  /**
   * @generated from field: annealing.delivery.v1.RolloutState state = 5;
   */
  state = RolloutState.UNSPECIFIED;

  /**
   * @generated from field: google.protobuf.Timestamp create_time = 6;
   */
  createTime?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp update_time = 7;
   */
  updateTime?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp start_time = 8;
   */
  startTime?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp finish_time = 9;
   */
  finishTime?: Timestamp;

  /**
   * @generated from field: int32 total_step_count = 10;
   */
  totalStepCount = 0;

  /**
   * @generated from field: int32 finished_step_count = 11;
   */
  finishedStepCount = 0;

  constructor(data?: PartialMessage<Rollout>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.Rollout";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "incarnation", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "asset_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "state", kind: "enum", T: proto3.getEnumType(RolloutState) },
    { no: 6, name: "create_time", kind: "message", T: Timestamp },
    { no: 7, name: "update_time", kind: "message", T: Timestamp },
    { no: 8, name: "start_time", kind: "message", T: Timestamp },
    { no: 9, name: "finish_time", kind: "message", T: Timestamp },
    { no: 10, name: "total_step_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 11, name: "finished_step_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Rollout {
    return new Rollout().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Rollout {
    return new Rollout().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Rollout {
    return new Rollout().fromJsonString(jsonString, options);
  }

  static equals(a: Rollout | PlainMessage<Rollout> | undefined, b: Rollout | PlainMessage<Rollout> | undefined): boolean {
    return proto3.util.equals(Rollout, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.RolloutStep
 */
export class RolloutStep extends Message<RolloutStep> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string description = 2;
   */
  description = "";

  /**
   * @generated from field: annealing.delivery.v1.RolloutStepKind kind = 3;
   */
  kind = RolloutStepKind.UNSPECIFIED;

  /**
   * @generated from field: annealing.delivery.v1.RolloutStepState state = 4;
   */
  state = RolloutStepState.UNSPECIFIED;

  /**
   * @generated from field: int32 wave = 5;
   */
  wave = 0;

  /**
   * @generated from field: int32 asset_count = 6;
   */
  assetCount = 0;

  /**
   * @generated from field: google.protobuf.Timestamp create_time = 7;
   */
  createTime?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp update_time = 8;
   */
  updateTime?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp start_time = 9;
   */
  startTime?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp finish_time = 10;
   */
  finishTime?: Timestamp;

  constructor(data?: PartialMessage<RolloutStep>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.RolloutStep";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "kind", kind: "enum", T: proto3.getEnumType(RolloutStepKind) },
    { no: 4, name: "state", kind: "enum", T: proto3.getEnumType(RolloutStepState) },
    { no: 5, name: "wave", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "asset_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "create_time", kind: "message", T: Timestamp },
    { no: 8, name: "update_time", kind: "message", T: Timestamp },
    { no: 9, name: "start_time", kind: "message", T: Timestamp },
    { no: 10, name: "finish_time", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RolloutStep {
    return new RolloutStep().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RolloutStep {
    return new RolloutStep().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RolloutStep {
    return new RolloutStep().fromJsonString(jsonString, options);
  }

  static equals(a: RolloutStep | PlainMessage<RolloutStep> | undefined, b: RolloutStep | PlainMessage<RolloutStep> | undefined): boolean {
    return proto3.util.equals(RolloutStep, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.RolloutAsset
 */
export class RolloutAsset extends Message<RolloutAsset> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: google.protobuf.Timestamp create_time = 2;
   */
  createTime?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp update_time = 3;
   */
  updateTime?: Timestamp;

  constructor(data?: PartialMessage<RolloutAsset>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.RolloutAsset";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "create_time", kind: "message", T: Timestamp },
    { no: 3, name: "update_time", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RolloutAsset {
    return new RolloutAsset().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RolloutAsset {
    return new RolloutAsset().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RolloutAsset {
    return new RolloutAsset().fromJsonString(jsonString, options);
  }

  static equals(a: RolloutAsset | PlainMessage<RolloutAsset> | undefined, b: RolloutAsset | PlainMessage<RolloutAsset> | undefined): boolean {
    return proto3.util.equals(RolloutAsset, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.ChartRepository
 */
export class ChartRepository extends Message<ChartRepository> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string uri = 2;
   */
  uri = "";

  /**
   * @generated from field: annealing.delivery.v1.ChartRepositoryState state = 3;
   */
  state = ChartRepositoryState.UNSPECIFIED;

  /**
   * @generated from field: google.protobuf.Timestamp create_time = 4;
   */
  createTime?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp update_time = 5;
   */
  updateTime?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp refresh_time = 6;
   */
  refreshTime?: Timestamp;

  constructor(data?: PartialMessage<ChartRepository>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.ChartRepository";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "uri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "state", kind: "enum", T: proto3.getEnumType(ChartRepositoryState) },
    { no: 4, name: "create_time", kind: "message", T: Timestamp },
    { no: 5, name: "update_time", kind: "message", T: Timestamp },
    { no: 6, name: "refresh_time", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ChartRepository {
    return new ChartRepository().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ChartRepository {
    return new ChartRepository().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ChartRepository {
    return new ChartRepository().fromJsonString(jsonString, options);
  }

  static equals(a: ChartRepository | PlainMessage<ChartRepository> | undefined, b: ChartRepository | PlainMessage<ChartRepository> | undefined): boolean {
    return proto3.util.equals(ChartRepository, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.GitRepository
 */
export class GitRepository extends Message<GitRepository> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string uri = 2;
   */
  uri = "";

  /**
   * @generated from field: annealing.delivery.v1.GitRepositoryState state = 3;
   */
  state = GitRepositoryState.UNSPECIFIED;

  /**
   * @generated from field: annealing.delivery.v1.GitRepositoryAuthentication authentication = 4;
   */
  authentication?: GitRepositoryAuthentication;

  /**
   * @generated from field: google.protobuf.Timestamp create_time = 5;
   */
  createTime?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp update_time = 6;
   */
  updateTime?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp refresh_time = 7;
   */
  refreshTime?: Timestamp;

  constructor(data?: PartialMessage<GitRepository>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.GitRepository";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "uri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "state", kind: "enum", T: proto3.getEnumType(GitRepositoryState) },
    { no: 4, name: "authentication", kind: "message", T: GitRepositoryAuthentication },
    { no: 5, name: "create_time", kind: "message", T: Timestamp },
    { no: 6, name: "update_time", kind: "message", T: Timestamp },
    { no: 7, name: "refresh_time", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GitRepository {
    return new GitRepository().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GitRepository {
    return new GitRepository().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GitRepository {
    return new GitRepository().fromJsonString(jsonString, options);
  }

  static equals(a: GitRepository | PlainMessage<GitRepository> | undefined, b: GitRepository | PlainMessage<GitRepository> | undefined): boolean {
    return proto3.util.equals(GitRepository, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.GitRepositoryAuthentication
 */
export class GitRepositoryAuthentication extends Message<GitRepositoryAuthentication> {
  /**
   * @generated from field: string ssh_key = 1;
   */
  sshKey = "";

  constructor(data?: PartialMessage<GitRepositoryAuthentication>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.GitRepositoryAuthentication";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ssh_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GitRepositoryAuthentication {
    return new GitRepositoryAuthentication().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GitRepositoryAuthentication {
    return new GitRepositoryAuthentication().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GitRepositoryAuthentication {
    return new GitRepositoryAuthentication().fromJsonString(jsonString, options);
  }

  static equals(a: GitRepositoryAuthentication | PlainMessage<GitRepositoryAuthentication> | undefined, b: GitRepositoryAuthentication | PlainMessage<GitRepositoryAuthentication> | undefined): boolean {
    return proto3.util.equals(GitRepositoryAuthentication, a, b);
  }
}

/**
 * @generated from message annealing.delivery.v1.Chart
 */
export class Chart extends Message<Chart> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string chart_version = 2;
   */
  chartVersion = "";

  /**
   * @generated from field: string app_version = 3;
   */
  appVersion = "";

  /**
   * @generated from field: string uri = 4;
   */
  uri = "";

  /**
   * @generated from field: string digest = 5;
   */
  digest = "";

  /**
   * @generated from field: annealing.delivery.v1.ChartState state = 6;
   */
  state = ChartState.UNSPECIFIED;

  /**
   * @generated from field: google.protobuf.Timestamp create_time = 7;
   */
  createTime?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp update_time = 8;
   */
  updateTime?: Timestamp;

  constructor(data?: PartialMessage<Chart>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.delivery.v1.Chart";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "chart_version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "app_version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "uri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "digest", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "state", kind: "enum", T: proto3.getEnumType(ChartState) },
    { no: 7, name: "create_time", kind: "message", T: Timestamp },
    { no: 8, name: "update_time", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Chart {
    return new Chart().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Chart {
    return new Chart().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Chart {
    return new Chart().fromJsonString(jsonString, options);
  }

  static equals(a: Chart | PlainMessage<Chart> | undefined, b: Chart | PlainMessage<Chart> | undefined): boolean {
    return proto3.util.equals(Chart, a, b);
  }
}

