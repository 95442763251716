// @generated by protoc-gen-es v1.8.0 with parameter "target=ts"
// @generated from file annealing/compute/v1/resources.proto (package annealing.compute.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { ClusterState, MachineState } from "./enums_pb.js";

/**
 * @generated from message annealing.compute.v1.Machine
 */
export class Machine extends Message<Machine> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: annealing.compute.v1.MachineState state = 2;
   */
  state = MachineState.UNSPECIFIED;

  /**
   * @generated from field: annealing.compute.v1.SystemInfo system_info = 3;
   */
  systemInfo?: SystemInfo;

  /**
   * @generated from field: google.protobuf.Timestamp create_time = 4;
   */
  createTime?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp update_time = 5;
   */
  updateTime?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp delete_time = 6;
   */
  deleteTime?: Timestamp;

  constructor(data?: PartialMessage<Machine>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.compute.v1.Machine";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "state", kind: "enum", T: proto3.getEnumType(MachineState) },
    { no: 3, name: "system_info", kind: "message", T: SystemInfo },
    { no: 4, name: "create_time", kind: "message", T: Timestamp },
    { no: 5, name: "update_time", kind: "message", T: Timestamp },
    { no: 6, name: "delete_time", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Machine {
    return new Machine().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Machine {
    return new Machine().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Machine {
    return new Machine().fromJsonString(jsonString, options);
  }

  static equals(a: Machine | PlainMessage<Machine> | undefined, b: Machine | PlainMessage<Machine> | undefined): boolean {
    return proto3.util.equals(Machine, a, b);
  }
}

/**
 * @generated from message annealing.compute.v1.SystemInfo
 */
export class SystemInfo extends Message<SystemInfo> {
  /**
   * @generated from field: annealing.compute.v1.OperatingSystemInfo operating_system = 1;
   */
  operatingSystem?: OperatingSystemInfo;

  /**
   * @generated from field: annealing.compute.v1.KernelInfo kernel = 2;
   */
  kernel?: KernelInfo;

  /**
   * @generated from field: annealing.compute.v1.HardwareInfo hardware = 3;
   */
  hardware?: HardwareInfo;

  /**
   * @generated from field: repeated annealing.compute.v1.NetworkInterface network_interfaces = 4;
   */
  networkInterfaces: NetworkInterface[] = [];

  constructor(data?: PartialMessage<SystemInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.compute.v1.SystemInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "operating_system", kind: "message", T: OperatingSystemInfo },
    { no: 2, name: "kernel", kind: "message", T: KernelInfo },
    { no: 3, name: "hardware", kind: "message", T: HardwareInfo },
    { no: 4, name: "network_interfaces", kind: "message", T: NetworkInterface, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SystemInfo {
    return new SystemInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SystemInfo {
    return new SystemInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SystemInfo {
    return new SystemInfo().fromJsonString(jsonString, options);
  }

  static equals(a: SystemInfo | PlainMessage<SystemInfo> | undefined, b: SystemInfo | PlainMessage<SystemInfo> | undefined): boolean {
    return proto3.util.equals(SystemInfo, a, b);
  }
}

/**
 * @generated from message annealing.compute.v1.OperatingSystemInfo
 */
export class OperatingSystemInfo extends Message<OperatingSystemInfo> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string version_id = 2;
   */
  versionId = "";

  constructor(data?: PartialMessage<OperatingSystemInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.compute.v1.OperatingSystemInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "version_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OperatingSystemInfo {
    return new OperatingSystemInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OperatingSystemInfo {
    return new OperatingSystemInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OperatingSystemInfo {
    return new OperatingSystemInfo().fromJsonString(jsonString, options);
  }

  static equals(a: OperatingSystemInfo | PlainMessage<OperatingSystemInfo> | undefined, b: OperatingSystemInfo | PlainMessage<OperatingSystemInfo> | undefined): boolean {
    return proto3.util.equals(OperatingSystemInfo, a, b);
  }
}

/**
 * @generated from message annealing.compute.v1.KernelInfo
 */
export class KernelInfo extends Message<KernelInfo> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string release = 2;
   */
  release = "";

  /**
   * @generated from field: string architecture = 3;
   */
  architecture = "";

  constructor(data?: PartialMessage<KernelInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.compute.v1.KernelInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "release", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "architecture", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): KernelInfo {
    return new KernelInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): KernelInfo {
    return new KernelInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): KernelInfo {
    return new KernelInfo().fromJsonString(jsonString, options);
  }

  static equals(a: KernelInfo | PlainMessage<KernelInfo> | undefined, b: KernelInfo | PlainMessage<KernelInfo> | undefined): boolean {
    return proto3.util.equals(KernelInfo, a, b);
  }
}

/**
 * @generated from message annealing.compute.v1.HardwareInfo
 */
export class HardwareInfo extends Message<HardwareInfo> {
  /**
   * @generated from field: int32 vcpu_count = 1;
   */
  vcpuCount = 0;

  /**
   * @generated from field: int64 total_memory_bytes = 2;
   */
  totalMemoryBytes = protoInt64.zero;

  constructor(data?: PartialMessage<HardwareInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.compute.v1.HardwareInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "vcpu_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "total_memory_bytes", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HardwareInfo {
    return new HardwareInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HardwareInfo {
    return new HardwareInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HardwareInfo {
    return new HardwareInfo().fromJsonString(jsonString, options);
  }

  static equals(a: HardwareInfo | PlainMessage<HardwareInfo> | undefined, b: HardwareInfo | PlainMessage<HardwareInfo> | undefined): boolean {
    return proto3.util.equals(HardwareInfo, a, b);
  }
}

/**
 * @generated from message annealing.compute.v1.NetworkInterface
 */
export class NetworkInterface extends Message<NetworkInterface> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string mac_address = 2;
   */
  macAddress = "";

  /**
   * @generated from field: repeated annealing.compute.v1.NetworkAddress ipv4_addresses = 3;
   */
  ipv4Addresses: NetworkAddress[] = [];

  /**
   * @generated from field: repeated annealing.compute.v1.NetworkAddress ipv6_addresses = 4;
   */
  ipv6Addresses: NetworkAddress[] = [];

  constructor(data?: PartialMessage<NetworkInterface>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.compute.v1.NetworkInterface";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "mac_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "ipv4_addresses", kind: "message", T: NetworkAddress, repeated: true },
    { no: 4, name: "ipv6_addresses", kind: "message", T: NetworkAddress, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NetworkInterface {
    return new NetworkInterface().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NetworkInterface {
    return new NetworkInterface().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NetworkInterface {
    return new NetworkInterface().fromJsonString(jsonString, options);
  }

  static equals(a: NetworkInterface | PlainMessage<NetworkInterface> | undefined, b: NetworkInterface | PlainMessage<NetworkInterface> | undefined): boolean {
    return proto3.util.equals(NetworkInterface, a, b);
  }
}

/**
 * @generated from message annealing.compute.v1.NetworkAddress
 */
export class NetworkAddress extends Message<NetworkAddress> {
  /**
   * @generated from field: string ip_address = 1;
   */
  ipAddress = "";

  /**
   * @generated from field: bool primary = 2;
   */
  primary = false;

  constructor(data?: PartialMessage<NetworkAddress>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.compute.v1.NetworkAddress";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ip_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "primary", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NetworkAddress {
    return new NetworkAddress().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NetworkAddress {
    return new NetworkAddress().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NetworkAddress {
    return new NetworkAddress().fromJsonString(jsonString, options);
  }

  static equals(a: NetworkAddress | PlainMessage<NetworkAddress> | undefined, b: NetworkAddress | PlainMessage<NetworkAddress> | undefined): boolean {
    return proto3.util.equals(NetworkAddress, a, b);
  }
}

/**
 * @generated from message annealing.compute.v1.Cluster
 */
export class Cluster extends Message<Cluster> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string description = 2;
   */
  description = "";

  /**
   * @generated from field: string uid = 3;
   */
  uid = "";

  /**
   * @generated from field: string endpoint = 4;
   */
  endpoint = "";

  /**
   * @generated from field: string version = 5;
   */
  version = "";

  /**
   * @generated from field: string certificate_authority = 6;
   */
  certificateAuthority = "";

  /**
   * @generated from field: annealing.compute.v1.ClusterState state = 7;
   */
  state = ClusterState.UNSPECIFIED;

  /**
   * @generated from field: annealing.compute.v1.ClusterNetworking networking = 8;
   */
  networking?: ClusterNetworking;

  /**
   * @generated from field: annealing.compute.v1.ClusterControlPlane control_plane = 9;
   */
  controlPlane?: ClusterControlPlane;

  /**
   * @generated from field: annealing.compute.v1.ClusterAuthorization authorization = 10;
   */
  authorization?: ClusterAuthorization;

  /**
   * @generated from field: google.protobuf.Timestamp create_time = 11;
   */
  createTime?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp update_time = 12;
   */
  updateTime?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp delete_time = 13;
   */
  deleteTime?: Timestamp;

  constructor(data?: PartialMessage<Cluster>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.compute.v1.Cluster";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "uid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "endpoint", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "certificate_authority", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "state", kind: "enum", T: proto3.getEnumType(ClusterState) },
    { no: 8, name: "networking", kind: "message", T: ClusterNetworking },
    { no: 9, name: "control_plane", kind: "message", T: ClusterControlPlane },
    { no: 10, name: "authorization", kind: "message", T: ClusterAuthorization },
    { no: 11, name: "create_time", kind: "message", T: Timestamp },
    { no: 12, name: "update_time", kind: "message", T: Timestamp },
    { no: 13, name: "delete_time", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Cluster {
    return new Cluster().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Cluster {
    return new Cluster().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Cluster {
    return new Cluster().fromJsonString(jsonString, options);
  }

  static equals(a: Cluster | PlainMessage<Cluster> | undefined, b: Cluster | PlainMessage<Cluster> | undefined): boolean {
    return proto3.util.equals(Cluster, a, b);
  }
}

/**
 * @generated from message annealing.compute.v1.ClusterNetworking
 */
export class ClusterNetworking extends Message<ClusterNetworking> {
  /**
   * @generated from field: repeated string pod_ipv4_cidr_blocks = 1;
   */
  podIpv4CidrBlocks: string[] = [];

  /**
   * @generated from field: repeated string service_ipv4_cidr_blocks = 2;
   */
  serviceIpv4CidrBlocks: string[] = [];

  constructor(data?: PartialMessage<ClusterNetworking>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.compute.v1.ClusterNetworking";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pod_ipv4_cidr_blocks", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "service_ipv4_cidr_blocks", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ClusterNetworking {
    return new ClusterNetworking().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ClusterNetworking {
    return new ClusterNetworking().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ClusterNetworking {
    return new ClusterNetworking().fromJsonString(jsonString, options);
  }

  static equals(a: ClusterNetworking | PlainMessage<ClusterNetworking> | undefined, b: ClusterNetworking | PlainMessage<ClusterNetworking> | undefined): boolean {
    return proto3.util.equals(ClusterNetworking, a, b);
  }
}

/**
 * @generated from message annealing.compute.v1.ClusterControlPlane
 */
export class ClusterControlPlane extends Message<ClusterControlPlane> {
  /**
   * @generated from field: repeated string machines = 1;
   */
  machines: string[] = [];

  /**
   * @generated from field: annealing.compute.v1.ClusterControlPlaneNodeConfig node_config = 2;
   */
  nodeConfig?: ClusterControlPlaneNodeConfig;

  constructor(data?: PartialMessage<ClusterControlPlane>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.compute.v1.ClusterControlPlane";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "machines", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "node_config", kind: "message", T: ClusterControlPlaneNodeConfig },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ClusterControlPlane {
    return new ClusterControlPlane().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ClusterControlPlane {
    return new ClusterControlPlane().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ClusterControlPlane {
    return new ClusterControlPlane().fromJsonString(jsonString, options);
  }

  static equals(a: ClusterControlPlane | PlainMessage<ClusterControlPlane> | undefined, b: ClusterControlPlane | PlainMessage<ClusterControlPlane> | undefined): boolean {
    return proto3.util.equals(ClusterControlPlane, a, b);
  }
}

/**
 * @generated from message annealing.compute.v1.ClusterControlPlaneNodeConfig
 */
export class ClusterControlPlaneNodeConfig extends Message<ClusterControlPlaneNodeConfig> {
  /**
   * @generated from field: bool register_node = 1;
   */
  registerNode = false;

  /**
   * @generated from field: annealing.compute.v1.MaxPodsConstraint max_pods_constraint = 2;
   */
  maxPodsConstraint?: MaxPodsConstraint;

  /**
   * @generated from field: repeated annealing.compute.v1.NodeTaint taints = 3;
   */
  taints: NodeTaint[] = [];

  /**
   * @generated from field: map<string, string> labels = 4;
   */
  labels: { [key: string]: string } = {};

  constructor(data?: PartialMessage<ClusterControlPlaneNodeConfig>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.compute.v1.ClusterControlPlaneNodeConfig";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "register_node", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "max_pods_constraint", kind: "message", T: MaxPodsConstraint },
    { no: 3, name: "taints", kind: "message", T: NodeTaint, repeated: true },
    { no: 4, name: "labels", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ClusterControlPlaneNodeConfig {
    return new ClusterControlPlaneNodeConfig().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ClusterControlPlaneNodeConfig {
    return new ClusterControlPlaneNodeConfig().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ClusterControlPlaneNodeConfig {
    return new ClusterControlPlaneNodeConfig().fromJsonString(jsonString, options);
  }

  static equals(a: ClusterControlPlaneNodeConfig | PlainMessage<ClusterControlPlaneNodeConfig> | undefined, b: ClusterControlPlaneNodeConfig | PlainMessage<ClusterControlPlaneNodeConfig> | undefined): boolean {
    return proto3.util.equals(ClusterControlPlaneNodeConfig, a, b);
  }
}

/**
 * @generated from message annealing.compute.v1.MaxPodsConstraint
 */
export class MaxPodsConstraint extends Message<MaxPodsConstraint> {
  /**
   * @generated from field: int64 max_pods_per_node = 1;
   */
  maxPodsPerNode = protoInt64.zero;

  constructor(data?: PartialMessage<MaxPodsConstraint>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.compute.v1.MaxPodsConstraint";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "max_pods_per_node", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MaxPodsConstraint {
    return new MaxPodsConstraint().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MaxPodsConstraint {
    return new MaxPodsConstraint().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MaxPodsConstraint {
    return new MaxPodsConstraint().fromJsonString(jsonString, options);
  }

  static equals(a: MaxPodsConstraint | PlainMessage<MaxPodsConstraint> | undefined, b: MaxPodsConstraint | PlainMessage<MaxPodsConstraint> | undefined): boolean {
    return proto3.util.equals(MaxPodsConstraint, a, b);
  }
}

/**
 * @generated from message annealing.compute.v1.NodeTaint
 */
export class NodeTaint extends Message<NodeTaint> {
  /**
   * @generated from field: string key = 1;
   */
  key = "";

  /**
   * @generated from field: string value = 2;
   */
  value = "";

  /**
   * @generated from field: string effect = 3;
   */
  effect = "";

  constructor(data?: PartialMessage<NodeTaint>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.compute.v1.NodeTaint";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "effect", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NodeTaint {
    return new NodeTaint().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NodeTaint {
    return new NodeTaint().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NodeTaint {
    return new NodeTaint().fromJsonString(jsonString, options);
  }

  static equals(a: NodeTaint | PlainMessage<NodeTaint> | undefined, b: NodeTaint | PlainMessage<NodeTaint> | undefined): boolean {
    return proto3.util.equals(NodeTaint, a, b);
  }
}

/**
 * @generated from message annealing.compute.v1.ClusterAuthorization
 */
export class ClusterAuthorization extends Message<ClusterAuthorization> {
  /**
   * @generated from field: repeated string admin_users = 1;
   */
  adminUsers: string[] = [];

  constructor(data?: PartialMessage<ClusterAuthorization>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.compute.v1.ClusterAuthorization";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "admin_users", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ClusterAuthorization {
    return new ClusterAuthorization().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ClusterAuthorization {
    return new ClusterAuthorization().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ClusterAuthorization {
    return new ClusterAuthorization().fromJsonString(jsonString, options);
  }

  static equals(a: ClusterAuthorization | PlainMessage<ClusterAuthorization> | undefined, b: ClusterAuthorization | PlainMessage<ClusterAuthorization> | undefined): boolean {
    return proto3.util.equals(ClusterAuthorization, a, b);
  }
}

/**
 * @generated from message annealing.compute.v1.OperationMetadata
 */
export class OperationMetadata extends Message<OperationMetadata> {
  /**
   * @generated from field: google.protobuf.Timestamp create_time = 1;
   */
  createTime?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp end_time = 2;
   */
  endTime?: Timestamp;

  /**
   * @generated from field: string target = 3;
   */
  target = "";

  /**
   * @generated from field: string status_detail = 4;
   */
  statusDetail = "";

  /**
   * @generated from field: string error_detail = 5;
   */
  errorDetail = "";

  /**
   * @generated from field: string verb = 6;
   */
  verb = "";

  /**
   * @generated from field: bool requested_cancellation = 7;
   */
  requestedCancellation = false;

  constructor(data?: PartialMessage<OperationMetadata>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.compute.v1.OperationMetadata";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "create_time", kind: "message", T: Timestamp },
    { no: 2, name: "end_time", kind: "message", T: Timestamp },
    { no: 3, name: "target", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "status_detail", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "error_detail", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "verb", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "requested_cancellation", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OperationMetadata {
    return new OperationMetadata().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OperationMetadata {
    return new OperationMetadata().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OperationMetadata {
    return new OperationMetadata().fromJsonString(jsonString, options);
  }

  static equals(a: OperationMetadata | PlainMessage<OperationMetadata> | undefined, b: OperationMetadata | PlainMessage<OperationMetadata> | undefined): boolean {
    return proto3.util.equals(OperationMetadata, a, b);
  }
}

