import { Dialog, Transition } from '@headlessui/react';
import {
  Bars3Icon,
  ChartBarIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { Fragment, Suspense, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { Route } from 'type-route';

import { groups, routes } from '../../router';
import { ErrorBoundary } from '../core/error_boundary';
import { Spinner } from '../core/spinner';
import { ProjectMenu } from './project_menu';
import { ProjectNavigation } from './project_navigation';
import { UserMenu } from './user_menu';

export interface SidebarLayoutProps {
  route: Route<typeof groups.project>;
  children: JSX.Element;
}

export function SidebarLayout(props: SidebarLayoutProps) {
  const { route, children } = props;
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div className="flex h-screen overflow-hidden bg-white">
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 z-40 flex md:hidden"
          open={sidebarOpen}
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex w-full max-w-xs flex-1 flex-col bg-white pt-5 pb-4">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XMarkIcon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </Transition.Child>
              <div className="h-0 flex-1 overflow-y-auto">
                <nav className="space-y-1 px-2">
                  <div className="mt-1 space-y-1">
                    <a
                      {...routes.projectDetail({
                        projectId: route.params.projectId,
                      }).link}
                      className="group flex items-center rounded-md px-2 py-2 text-base font-medium leading-6 text-gray-600 transition duration-150 ease-in-out hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900 focus:outline-none"
                    >
                      <ChartBarIcon className="mr-4 h-6 w-6 text-gray-500 transition duration-150 ease-in-out group-hover:text-gray-500 group-focus:text-gray-600" />
                      Dashboard
                    </a>
                  </div>
                </nav>
              </div>
            </div>
          </Transition.Child>
          <div className="w-14 flex-shrink-0"></div>
        </Dialog>
      </Transition.Root>
      <div className="hidden md:flex md:flex-shrink-0">
        <div className="flex w-64 flex-col">
          <div className="flex min-h-0 flex-grow flex-col overflow-y-auto border-r border-gray-300 bg-gray-50 dark:bg-gray-800">
            <div className="flex flex-1 flex-col overflow-y-auto py-1">
              <div className="flex flex-1 flex-col">
                <ErrorBoundary>
                  <Suspense fallback={<Spinner />}>
                    <ProjectMenu projectId={route.params.projectId} />
                  </Suspense>
                </ErrorBoundary>
                <ErrorBoundary>
                  <Suspense fallback={<Spinner />}>
                    <ProjectNavigation
                      projectId={route.params.projectId}
                    />
                  </Suspense>
                </ErrorBoundary>
                <div className="mt-auto flex flex-col">
                  <ErrorBoundary>
                    <Suspense fallback={<Spinner />}>
                      <UserMenu />
                    </Suspense>
                  </ErrorBoundary>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex w-0 flex-1 flex-col overflow-hidden">
        <div className="pt-1 pl-1 sm:pl-3 sm:pt-3 md:hidden">
          <button
            className="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" />
          </button>
        </div>
        <main
          className="relative z-0 flex-1 overflow-y-auto focus:outline-none"
          tabIndex={0}
        >
          <div className="mx-auto px-4 sm:px-6 md:px-8">
            <div className="py-4">{children}</div>
          </div>
        </main>
      </div>
      <Toaster position="bottom-center" containerStyle={{ bottom: 24 }} />
    </div>
  );
}
