// @generated by protoc-gen-es v1.8.0 with parameter "target=ts"
// @generated from file annealing/iam/v1/service.proto (package annealing.iam.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { IdentityProvider, RoleBinding, ServiceAccount } from "./resources_pb.js";

/**
 * @generated from message annealing.iam.v1.ListIdentityProvidersRequest
 */
export class ListIdentityProvidersRequest extends Message<ListIdentityProvidersRequest> {
  /**
   * @generated from field: string parent = 1;
   */
  parent = "";

  /**
   * @generated from field: int32 page_size = 2;
   */
  pageSize = 0;

  /**
   * @generated from field: string page_token = 3;
   */
  pageToken = "";

  constructor(data?: PartialMessage<ListIdentityProvidersRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.iam.v1.ListIdentityProvidersRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "parent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListIdentityProvidersRequest {
    return new ListIdentityProvidersRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListIdentityProvidersRequest {
    return new ListIdentityProvidersRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListIdentityProvidersRequest {
    return new ListIdentityProvidersRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListIdentityProvidersRequest | PlainMessage<ListIdentityProvidersRequest> | undefined, b: ListIdentityProvidersRequest | PlainMessage<ListIdentityProvidersRequest> | undefined): boolean {
    return proto3.util.equals(ListIdentityProvidersRequest, a, b);
  }
}

/**
 * @generated from message annealing.iam.v1.ListIdentityProvidersResponse
 */
export class ListIdentityProvidersResponse extends Message<ListIdentityProvidersResponse> {
  /**
   * @generated from field: repeated annealing.iam.v1.IdentityProvider identity_providers = 1;
   */
  identityProviders: IdentityProvider[] = [];

  /**
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = "";

  /**
   * @generated from field: string previous_page_token = 3;
   */
  previousPageToken = "";

  /**
   * @generated from field: int32 total_size = 4;
   */
  totalSize = 0;

  /**
   * @generated from field: int32 start_index = 5;
   */
  startIndex = 0;

  constructor(data?: PartialMessage<ListIdentityProvidersResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.iam.v1.ListIdentityProvidersResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "identity_providers", kind: "message", T: IdentityProvider, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "previous_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "total_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "start_index", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListIdentityProvidersResponse {
    return new ListIdentityProvidersResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListIdentityProvidersResponse {
    return new ListIdentityProvidersResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListIdentityProvidersResponse {
    return new ListIdentityProvidersResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListIdentityProvidersResponse | PlainMessage<ListIdentityProvidersResponse> | undefined, b: ListIdentityProvidersResponse | PlainMessage<ListIdentityProvidersResponse> | undefined): boolean {
    return proto3.util.equals(ListIdentityProvidersResponse, a, b);
  }
}

/**
 * @generated from message annealing.iam.v1.GetIdentityProviderRequest
 */
export class GetIdentityProviderRequest extends Message<GetIdentityProviderRequest> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  constructor(data?: PartialMessage<GetIdentityProviderRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.iam.v1.GetIdentityProviderRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetIdentityProviderRequest {
    return new GetIdentityProviderRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetIdentityProviderRequest {
    return new GetIdentityProviderRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetIdentityProviderRequest {
    return new GetIdentityProviderRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetIdentityProviderRequest | PlainMessage<GetIdentityProviderRequest> | undefined, b: GetIdentityProviderRequest | PlainMessage<GetIdentityProviderRequest> | undefined): boolean {
    return proto3.util.equals(GetIdentityProviderRequest, a, b);
  }
}

/**
 * @generated from message annealing.iam.v1.CreateIdentityProviderRequest
 */
export class CreateIdentityProviderRequest extends Message<CreateIdentityProviderRequest> {
  /**
   * @generated from field: string parent = 1;
   */
  parent = "";

  /**
   * @generated from field: annealing.iam.v1.IdentityProvider identity_provider = 2;
   */
  identityProvider?: IdentityProvider;

  /**
   * @generated from field: string identity_provider_id = 3;
   */
  identityProviderId = "";

  constructor(data?: PartialMessage<CreateIdentityProviderRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.iam.v1.CreateIdentityProviderRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "parent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "identity_provider", kind: "message", T: IdentityProvider },
    { no: 3, name: "identity_provider_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateIdentityProviderRequest {
    return new CreateIdentityProviderRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateIdentityProviderRequest {
    return new CreateIdentityProviderRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateIdentityProviderRequest {
    return new CreateIdentityProviderRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateIdentityProviderRequest | PlainMessage<CreateIdentityProviderRequest> | undefined, b: CreateIdentityProviderRequest | PlainMessage<CreateIdentityProviderRequest> | undefined): boolean {
    return proto3.util.equals(CreateIdentityProviderRequest, a, b);
  }
}

/**
 * @generated from message annealing.iam.v1.DeleteIdentityProviderRequest
 */
export class DeleteIdentityProviderRequest extends Message<DeleteIdentityProviderRequest> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  constructor(data?: PartialMessage<DeleteIdentityProviderRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.iam.v1.DeleteIdentityProviderRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteIdentityProviderRequest {
    return new DeleteIdentityProviderRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteIdentityProviderRequest {
    return new DeleteIdentityProviderRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteIdentityProviderRequest {
    return new DeleteIdentityProviderRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteIdentityProviderRequest | PlainMessage<DeleteIdentityProviderRequest> | undefined, b: DeleteIdentityProviderRequest | PlainMessage<DeleteIdentityProviderRequest> | undefined): boolean {
    return proto3.util.equals(DeleteIdentityProviderRequest, a, b);
  }
}

/**
 * @generated from message annealing.iam.v1.ListRoleBindingsRequest
 */
export class ListRoleBindingsRequest extends Message<ListRoleBindingsRequest> {
  /**
   * @generated from field: string parent = 1;
   */
  parent = "";

  /**
   * @generated from field: int32 page_size = 2;
   */
  pageSize = 0;

  /**
   * @generated from field: string page_token = 3;
   */
  pageToken = "";

  constructor(data?: PartialMessage<ListRoleBindingsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.iam.v1.ListRoleBindingsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "parent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListRoleBindingsRequest {
    return new ListRoleBindingsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListRoleBindingsRequest {
    return new ListRoleBindingsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListRoleBindingsRequest {
    return new ListRoleBindingsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListRoleBindingsRequest | PlainMessage<ListRoleBindingsRequest> | undefined, b: ListRoleBindingsRequest | PlainMessage<ListRoleBindingsRequest> | undefined): boolean {
    return proto3.util.equals(ListRoleBindingsRequest, a, b);
  }
}

/**
 * @generated from message annealing.iam.v1.ListRoleBindingsResponse
 */
export class ListRoleBindingsResponse extends Message<ListRoleBindingsResponse> {
  /**
   * @generated from field: repeated annealing.iam.v1.RoleBinding role_bindings = 1;
   */
  roleBindings: RoleBinding[] = [];

  /**
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = "";

  /**
   * @generated from field: string previous_page_token = 3;
   */
  previousPageToken = "";

  /**
   * @generated from field: int32 total_size = 4;
   */
  totalSize = 0;

  /**
   * @generated from field: int32 start_index = 5;
   */
  startIndex = 0;

  constructor(data?: PartialMessage<ListRoleBindingsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.iam.v1.ListRoleBindingsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "role_bindings", kind: "message", T: RoleBinding, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "previous_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "total_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "start_index", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListRoleBindingsResponse {
    return new ListRoleBindingsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListRoleBindingsResponse {
    return new ListRoleBindingsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListRoleBindingsResponse {
    return new ListRoleBindingsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListRoleBindingsResponse | PlainMessage<ListRoleBindingsResponse> | undefined, b: ListRoleBindingsResponse | PlainMessage<ListRoleBindingsResponse> | undefined): boolean {
    return proto3.util.equals(ListRoleBindingsResponse, a, b);
  }
}

/**
 * @generated from message annealing.iam.v1.GetRoleBindingRequest
 */
export class GetRoleBindingRequest extends Message<GetRoleBindingRequest> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  constructor(data?: PartialMessage<GetRoleBindingRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.iam.v1.GetRoleBindingRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetRoleBindingRequest {
    return new GetRoleBindingRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetRoleBindingRequest {
    return new GetRoleBindingRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetRoleBindingRequest {
    return new GetRoleBindingRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetRoleBindingRequest | PlainMessage<GetRoleBindingRequest> | undefined, b: GetRoleBindingRequest | PlainMessage<GetRoleBindingRequest> | undefined): boolean {
    return proto3.util.equals(GetRoleBindingRequest, a, b);
  }
}

/**
 * @generated from message annealing.iam.v1.CreateRoleBindingRequest
 */
export class CreateRoleBindingRequest extends Message<CreateRoleBindingRequest> {
  /**
   * @generated from field: string parent = 1;
   */
  parent = "";

  /**
   * @generated from field: annealing.iam.v1.RoleBinding role_binding = 2;
   */
  roleBinding?: RoleBinding;

  /**
   * @generated from field: string role_binding_id = 3;
   */
  roleBindingId = "";

  constructor(data?: PartialMessage<CreateRoleBindingRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.iam.v1.CreateRoleBindingRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "parent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "role_binding", kind: "message", T: RoleBinding },
    { no: 3, name: "role_binding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateRoleBindingRequest {
    return new CreateRoleBindingRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateRoleBindingRequest {
    return new CreateRoleBindingRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateRoleBindingRequest {
    return new CreateRoleBindingRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateRoleBindingRequest | PlainMessage<CreateRoleBindingRequest> | undefined, b: CreateRoleBindingRequest | PlainMessage<CreateRoleBindingRequest> | undefined): boolean {
    return proto3.util.equals(CreateRoleBindingRequest, a, b);
  }
}

/**
 * @generated from message annealing.iam.v1.DeleteRoleBindingRequest
 */
export class DeleteRoleBindingRequest extends Message<DeleteRoleBindingRequest> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  constructor(data?: PartialMessage<DeleteRoleBindingRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.iam.v1.DeleteRoleBindingRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteRoleBindingRequest {
    return new DeleteRoleBindingRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteRoleBindingRequest {
    return new DeleteRoleBindingRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteRoleBindingRequest {
    return new DeleteRoleBindingRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteRoleBindingRequest | PlainMessage<DeleteRoleBindingRequest> | undefined, b: DeleteRoleBindingRequest | PlainMessage<DeleteRoleBindingRequest> | undefined): boolean {
    return proto3.util.equals(DeleteRoleBindingRequest, a, b);
  }
}

/**
 * @generated from message annealing.iam.v1.ListServiceAccountsRequest
 */
export class ListServiceAccountsRequest extends Message<ListServiceAccountsRequest> {
  /**
   * @generated from field: string parent = 1;
   */
  parent = "";

  /**
   * @generated from field: int32 page_size = 2;
   */
  pageSize = 0;

  /**
   * @generated from field: string page_token = 3;
   */
  pageToken = "";

  constructor(data?: PartialMessage<ListServiceAccountsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.iam.v1.ListServiceAccountsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "parent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListServiceAccountsRequest {
    return new ListServiceAccountsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListServiceAccountsRequest {
    return new ListServiceAccountsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListServiceAccountsRequest {
    return new ListServiceAccountsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListServiceAccountsRequest | PlainMessage<ListServiceAccountsRequest> | undefined, b: ListServiceAccountsRequest | PlainMessage<ListServiceAccountsRequest> | undefined): boolean {
    return proto3.util.equals(ListServiceAccountsRequest, a, b);
  }
}

/**
 * @generated from message annealing.iam.v1.ListServiceAccountsResponse
 */
export class ListServiceAccountsResponse extends Message<ListServiceAccountsResponse> {
  /**
   * @generated from field: repeated annealing.iam.v1.ServiceAccount service_accounts = 1;
   */
  serviceAccounts: ServiceAccount[] = [];

  /**
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = "";

  /**
   * @generated from field: string previous_page_token = 3;
   */
  previousPageToken = "";

  /**
   * @generated from field: int32 total_size = 4;
   */
  totalSize = 0;

  /**
   * @generated from field: int32 start_index = 5;
   */
  startIndex = 0;

  constructor(data?: PartialMessage<ListServiceAccountsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.iam.v1.ListServiceAccountsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "service_accounts", kind: "message", T: ServiceAccount, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "previous_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "total_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "start_index", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListServiceAccountsResponse {
    return new ListServiceAccountsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListServiceAccountsResponse {
    return new ListServiceAccountsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListServiceAccountsResponse {
    return new ListServiceAccountsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListServiceAccountsResponse | PlainMessage<ListServiceAccountsResponse> | undefined, b: ListServiceAccountsResponse | PlainMessage<ListServiceAccountsResponse> | undefined): boolean {
    return proto3.util.equals(ListServiceAccountsResponse, a, b);
  }
}

/**
 * @generated from message annealing.iam.v1.GetServiceAccountRequest
 */
export class GetServiceAccountRequest extends Message<GetServiceAccountRequest> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  constructor(data?: PartialMessage<GetServiceAccountRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.iam.v1.GetServiceAccountRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetServiceAccountRequest {
    return new GetServiceAccountRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetServiceAccountRequest {
    return new GetServiceAccountRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetServiceAccountRequest {
    return new GetServiceAccountRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetServiceAccountRequest | PlainMessage<GetServiceAccountRequest> | undefined, b: GetServiceAccountRequest | PlainMessage<GetServiceAccountRequest> | undefined): boolean {
    return proto3.util.equals(GetServiceAccountRequest, a, b);
  }
}

/**
 * @generated from message annealing.iam.v1.CreateServiceAccountRequest
 */
export class CreateServiceAccountRequest extends Message<CreateServiceAccountRequest> {
  /**
   * @generated from field: string parent = 1;
   */
  parent = "";

  /**
   * @generated from field: annealing.iam.v1.ServiceAccount service_account = 2;
   */
  serviceAccount?: ServiceAccount;

  /**
   * @generated from field: string service_account_id = 3;
   */
  serviceAccountId = "";

  constructor(data?: PartialMessage<CreateServiceAccountRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.iam.v1.CreateServiceAccountRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "parent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "service_account", kind: "message", T: ServiceAccount },
    { no: 3, name: "service_account_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateServiceAccountRequest {
    return new CreateServiceAccountRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateServiceAccountRequest {
    return new CreateServiceAccountRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateServiceAccountRequest {
    return new CreateServiceAccountRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateServiceAccountRequest | PlainMessage<CreateServiceAccountRequest> | undefined, b: CreateServiceAccountRequest | PlainMessage<CreateServiceAccountRequest> | undefined): boolean {
    return proto3.util.equals(CreateServiceAccountRequest, a, b);
  }
}

/**
 * @generated from message annealing.iam.v1.DeleteServiceAccountRequest
 */
export class DeleteServiceAccountRequest extends Message<DeleteServiceAccountRequest> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  constructor(data?: PartialMessage<DeleteServiceAccountRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "annealing.iam.v1.DeleteServiceAccountRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteServiceAccountRequest {
    return new DeleteServiceAccountRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteServiceAccountRequest {
    return new DeleteServiceAccountRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteServiceAccountRequest {
    return new DeleteServiceAccountRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteServiceAccountRequest | PlainMessage<DeleteServiceAccountRequest> | undefined, b: DeleteServiceAccountRequest | PlainMessage<DeleteServiceAccountRequest> | undefined): boolean {
    return proto3.util.equals(DeleteServiceAccountRequest, a, b);
  }
}

